.cta {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	transition: all 2s;
	z-index: 3;

	.ctaScroll {
		@include ctaCircle;
		animation: ctaScrollBounce 6s infinite ease-in-out;
	}
}
