.menuOpenActive {
	color: $primary;

	.language {
		color: $primary;
	}

	.borderLineHead {
		border-bottom: 2px solid $primary !important;
	}

	.cls-1,
	.st0 {
		fill: $primary;
		stroke: $primary;
	}
}

.pageMetierActive {
	color: $blue;

	.language {
		color: $blue;
	}

	.cls-1,
	.st0 {
		fill: $blue;
		stroke: $blue;
	}

	.borderLineHead {
		border-bottom: 2px solid $blue !important;
	}
}

.contactMenu {
	.st0 {
		fill: $primary;
	}
}

.cls-1,
.st0 {
	transition: all 0.5s;
}

#mainMenu {
	position: fixed;
	z-index: 1000;
	height: 100vh;
	width: 100%;
	display: flex;
	background-color: $beige;
	transform: translateX(100%);
	transition: transform 0.5s;
	top: 0;

	@include media("<laptop") {
		height: 100%;
		overflow: scroll;
	}

	.main {
		max-width: 1445px;
		margin: auto;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	.menu {
		height: 100%;
		display: flex;
		width: 100%;

		@include media("<laptopXL") {
			padding: 0 10px;
		}

		@include media("<laptop") {
			flex-direction: column;
			margin-top: 100px;
			padding: 0 25px;
		}

		@include media("<tablet") {
			height: auto;
		}
	}

	.active {
		opacity: 1;
		transition: opacity 1000ms;
	}

	.activeSvg {
		transform: rotate(180deg) !important;
		transition: transform 500ms;
	}

	.fadeActive {
		@for $i from 0 through 7 {
			&#{$i} {
				transform: translateX(-50px);
				transition: transform 1s (100ms * $i);
			}
		}
	}

	@import "./left-section.scss";
	@import "./middle-section.scss";
	@import "./right-section.scss";
}
