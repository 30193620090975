#article {
	background-color: #fff;

	#articleIntro {
		min-height: 100vh;

		.bgIntro {
			position: absolute;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.bgRecru {
			filter: brightness(0.5);
		}

		h1 {
			z-index: 1;
			color: #fff;
			font-family: "Gilroy-Bold";
			font-size: 100px;
			padding: 150px 0 0 90px;
			position: relative;

			@include media("<tablet") {
				font-size: 68px;
				line-height: 90px;
				padding-left: 20px;
			}
		}
	}

	@import "./content.scss";
}
