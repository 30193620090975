@mixin gridLayout {
	display: grid;
	grid-template-columns: repeat(22, minmax(0px, 1fr));
	gap: 0 $gridGap;
	max-width: $maxWidth + $gridMargin * 2;
	margin-left: auto;
	margin-right: auto;
	padding-left: $gridMargin;
	padding-right: $gridMargin;

	@include media("<tablet") {
		padding-left: $padding;
		padding-right: $padding;
	}
}

@mixin scrimGradient($startColor: $color-black, $direction: "to bottom") {
	$scrimCoordinates: (
		0: 1,
		19: 0.738,
		34: 0.541,
		47: 0.382,
		56.5: 0.278,
		65: 0.194,
		73: 0.126,
		80.2: 0.075,
		86.1: 0.042,
		91: 0.021,
		95.2: 0.008,
		98.2: 0.002,
		100: 0,
	);

	$hue: hue($startColor);
	$saturation: saturation($startColor);
	$lightness: lightness($startColor);
	$stops: ();

	@each $colorStop, $alphaValue in $scrimCoordinates {
		$stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
		$stops: append($stops, $stop, comma);
	}

	background-image: linear-gradient(unquote($direction), $stops);
}

@mixin ctaCircle($circleWidth: 50px) {
	display: block;
	position: absolute;
	width: $circleWidth;
	height: $circleWidth;
	font-size: $circleWidth;
	left: 0;
	right: 0;
	bottom: 50px;
	margin: auto;

	@include media("<laptop") {
		right: 15px;
		left: initial;
	}

	@include media("<mobileXL") {
		bottom: 95px;
		width: 32px;
		height: 32px;
		right: 0;
		left: 0;
	}

	@include media("<mobileS") {
		bottom: 85px;
	}

	// Cercle
	span {
		display: block;
		height: 100%;
		border-radius: 50%;
		box-shadow: 0 0 0 2px #fff;
		transition-duration: 0.2s;
		transition-property: box-shadow, opacity, transform;

		@include media("<mobileXL") {
			box-shadow: 0 0 0 1px #fff;
		}
	}

	// Trait
	&::before {
		content: "";
		position: absolute;
		bottom: 0.3em;
		top: 0.25em;
		left: 0;
		right: 0;
		width: 2px;
		margin: auto;
		background-color: #fff;
		transition: 0.2s transform;
		z-index: 3;

		@include media("<mobileXL") {
			bottom: 0.22em;
			top: 0.15em;
			width: 1px;
		}
	}

	// Flèche
	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0.3em;
		margin: auto;
		transition: 0.2s transform;
		width: 14px;
		height: 14px;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
		z-index: 3;

		@include media("<mobileXL") {
			bottom: 0.23em;
			border-left: 1px solid #fff;
			border-bottom: 1px solid #fff;
			width: 10px;
			height: 10px;
		}
	}

	&:hover {
		span {
			// 	// box-shadow: 0 0 0 2px #fff;
			transform: scale(1.1);
		}

		&::before {
			transform: scaleY(1.1);
		}

		&::after {
			transform: rotate(-45deg) scale(1.1);
		}
	}
}

@mixin display-subsection($left) {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 205px;
	height: 42px;
	border-radius: 20px;
	position: absolute;
	left: $left;
	color: white;
	border: 1px solid white;
}

@mixin display-subsection-right($right) {
	color: white;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 205px;
	height: 42px;
	border-radius: 20px;
	position: absolute;
	right: $right;
}

@mixin display-row {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 20%;
}

@mixin display-little-border-left($top) {
	width: 1.5%;
	border: 1px solid white;
	position: absolute;
	top: $top;
	left: 12%;
}

@mixin display-little-border-middle-left($top) {
	position: absolute;
	top: $top;
	left: 35%;
	border: 1px solid white;
	width: 1.5%;
}

@mixin display-little-border-middle-right($top) {
	position: absolute;
	width: 2%;
	top: $top;
	right: 32%;
	border: 1px solid white;
}

@mixin display-little-border-mliddle-right($top) {
	border: 1px solid white;
	width: 1.2%;
	position: absolute;
	top: $top;
	right: 11%;
}

@mixin display-little-border-middle-bottom($left) {
	width: 10%;
	border: 1px solid white;
	position: absolute;
	top: 34%;
	left: $left;
}

@mixin display-little-border-middle-bottom-right-left($left) {
	width: 2%;
	position: absolute;
	top: 34%;
	left: $left;
	border: 1px solid white;
	transform: rotate(90deg);
}

@mixin subtitle($left) {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	position: absolute;
	left: $left;
	text-align: center;
	width: 300px;
	height: 200px;
	border-radius: 50%;
	text-transform: uppercase;
	opacity: 0;
	color: white;
}

@mixin display-border($top, $left, $width) {
	display: flex;
	position: absolute;
	top: $top;
	left: $left;
	color: white;
	width: $width;
	border: 1px solid white;
	opacity: 0;
}

@mixin display-border-right($top, $right, $width) {
	position: absolute;
	top: $top;
	right: $right;
	width: $width;
	border: 1px solid white;
	color: white;
	opacity: 0;
}

@mixin rightSectionSubDisplay {
	display: flex;
	justify-content: space-between;
	font-family: Helvetica;
	color: #262422;
}

@mixin display-section-plus($dimension) {
	position: relative;
	top: $dimension;
	width: 100%;
	z-index: 1;
	height: 19%;
}

@mixin display-svg($dimension) {
	border: 1px solid #545251;
	border-radius: 5px;
	width: 12px;
	height: 0.1px;
	position: absolute;
	top: $dimension;
	left: 50px;
}

@mixin rotate-svg {
	transform: rotate(90deg);
	transition: transform 200ms;
}

@mixin display-content($dimension, $moins: 0) {
	position: absolute;
	top: $dimension;
	// left: 110px;
	left: 0px;
	width: 75%;
	overflow: hidden;

	@include media("<desktopXXL") {
		$dimensionDesktop: $dimension - 55 - $moins;
		top: $dimensionDesktop;
	}

	@include media("<desktopXL") {
		$dimensionDesktop: $dimension - 72 - $moins;
		top: $dimensionDesktop;
	}

	@include media("<desktop") {
		$dimensionDesktop: $dimension - 102 - $moins;
		top: $dimensionDesktop;
	}

	p {
		transform: translateX(-190px);
		padding: 2px;
		width: max-content;
	}
}

@mixin text($color: $primary) {
	color: $color;
	font-weight: 700;
}

@mixin cssPlus {
	&::before {
		content: "";
		position: absolute;
		width: 15px;
		height: 1px;
		background-color: $primary;
		right: 0;
		top: 35px;

		@include media("<tablet") {
			top: 30px;
			width: 13px;
		}
	}

	&::after {
		content: "";
		position: absolute;
		width: 1px;
		height: 15px;
		background-color: $primary;
		right: 7px;
		top: 28px;
		transition: all 0.3s;

		@include media("<tablet") {
			top: 25px;
			right: 6px;
			height: 12px;
		}
	}
}

// --- Organigramme ---
@mixin display-subsection($left) {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 205px;
	height: 42px;
	border-radius: 20px;
	position: absolute;
	left: $left;
	color: white;
	border: 1px solid white;
	@include media("<mobileXL") {
		width: 180px;
	}
}

@mixin display-subsection-right($right) {
	color: white;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 205px;
	height: 42px;
	border-radius: 20px;
	position: absolute;
	right: $right;
	// opacity: 0;
}

@mixin display-row {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 20%;
}

@mixin display-little-border-left($top) {
	width: 1.5%;
	border: 1px solid white;
	position: absolute;
	top: $top;
	left: 10%;
	@include media("<mobileXL") {
		width: 8.5%;
		left: 22%;
		opacity: 0;
	}
}

@mixin display-little-border-middle-left($top) {
	position: absolute;
	top: $top;
	left: 32%;
	border: 1px solid white;
	width: 1.5%;
}

@mixin display-little-border-middle-right($top) {
	position: absolute;
	width: 1.5%;
	top: $top;
	right: 30%;
	border: 1px solid white;
}

@mixin display-little-border-mliddle-right($top) {
	border: 1px solid white;
	width: 1.2%;
	position: absolute;
	top: $top;
	right: 9%;
}

@mixin display-little-border-middle-bottom($left) {
	width: 10%;
	border: 1px solid white;
	position: absolute;
	top: 34%;
	left: $left;
}

@mixin display-little-border-middle-bottom-right-left($left) {
	width: 2%;
	position: absolute;
	top: 34%;
	left: $left;
	border: 1px solid white;
	transform: rotate(90deg);
}

@mixin subtitle($left) {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	position: absolute;
	left: $left;
	text-align: center;
	width: 300px;
	height: 200px;
	border-radius: 50%;
	text-transform: uppercase;
	opacity: 0;
	color: white;
}

@mixin display-border($top, $left, $width) {
	display: flex;
	position: absolute;
	top: $top;
	left: $left;
	color: white;
	width: $width;
	border: 1px solid white;
	@include media("<mobileXL") {
		opacity: 0;
	}
}

@mixin display-border-right($top, $right, $width) {
	position: absolute;
	top: $top;
	right: $right;
	width: $width;
	border: 1px solid white;
	color: white;
	@include media("<mobileXL") {
		opacity: 0;
	}
	// opacity: 0;
}

@mixin borderHiding($x, $y) {
	transform: translate($x, $y);
	fill: #262422;
	stroke: #262422;
}
