#footer {
    @include media("<tablet") {
        display: none;
    }

    &[class~="invertedBlue"] {
        background-color: #fff;

        & * {
            color: $blue;
            border-color: $blue;
            fill: $blue;
        }
    }
}

.footer {
    background-color: $primary;

    .mrg-left-80-px {
        @include media("<tablet") {
            margin-left: 0;
        }
    }
}

.footer-top {
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    padding: 0px 40px;
    justify-content: space-between;
}

.mgt-15prc {
    margin-top: 15%;
}

.special-footer-h3 {
    margin-top: -33%;
    font-size: 11px;
}

.mail-footer {
    border: none;
    background: none;
    border-bottom: 1px solid #ffffff;
    padding: 10px 0;
    font-size: 8px;
    color: #a8a9ae;
}

.mail-footer:focus {
    outline: none;
}

.space-svg-footer {
    width: 8px;
}

.footer-bottom {
    color: #ffffff;
    font-family: Gilroy-Bold;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    //padding: 0 40px;
    margin-left: 0;
    margin-bottom: 50px;

    @include media("<laptopM") {
        margin: 0;
        margin-bottom: 75px;
    }
}

.footer-bottom ul li a {
    color: #ffffff;
}

.footer-bottom ul li:nth-last-child(2) {
    margin: 0 50px 0 50px;
}
.footer-bottom ul li:nth-last-child(3) {
    margin: 0 50px 0 50px;
}
.wrapper-footer {
    width: 100%;
    max-width: 80vw;
}

.newsletter-form {
    margin-top: 30px;
}
.footer-midel-form {
    width: 20%;
}
#wpcf7-f1200-o1  .wpcf7-form {
    text-align: center;

    .newsletter-form {
        width: 100%;
        border: none;
        background: none;
        border-bottom: 1px solid #ffffff;
        font-size: 12px;
        color: inherit;
        text-align: left;
        margin: 5px;
    }
    .wpcf7-response-output {
        text-align: center;
    }
    .wpcf7-submit {
        display: block;
        margin: auto;
    }
    .wpcf7-response-output {
        margin: 0;
    }
}

#wpcf7-f1272-o1 .wpcf7-form {
    text-align: center;

    .newsletter-form {
        width: 100%;
        border: none;
        background: none;
        border-bottom: 1px solid #ffffff;
        font-size: 12px;
        color: inherit;
        text-align: left;
        margin: 5px;
    }
    .wpcf7-response-output {
        text-align: center;
    }
    .wpcf7-submit {
        display: block;
        margin: auto;
    }
    .wpcf7-response-output {
        margin: 0;
    }
}

@include media("<mobileXL") {
    .footer {
        display: none;
        .wrapper-footer {
            width: 100%;
            max-width: 100%;
            .footer-top {
                padding: 0;
                flex-direction: column;
                .footer-midel-form {
                    width: 100%;
                    border: 2px solid red;
                }
            }
            .footer-bottom {
                padding: 0;
                justify-content: center;
                ul {
                    flex-direction: column;
                    text-align: center;
                }
            }
        }
    }
}

.facebookwrapper, .wttjwrapper {
    margin-left: 5px;
}

.wttjwrapper {
    position: relative;
    top: 11px; 
}
