#actualitesHome {
    min-height: 100vh;
    display: flex;
}

#homeActu {
    width: 100vw;
    // background-color: $primary;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    max-width: 80vw;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    @include media("<desktop") {
        padding-top: 85px;
    }

    @include media("<laptopXL") {
        padding-top: 70px;
    }

    @include media("<laptopL") {
        padding-top: 85px;
    }

    @include media("<tablet") {
        max-width: 100%;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin: auto;
    }

    .containerTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media("<tablet") {
            margin-top: 50px;
        }
        @include media("<mobileL") {
            justify-content: flex-start;
        }
    }

    .overTitle {
        overflow: hidden;

        .actuTitle {
            font-size: 80px;
            padding-left: 40px;
            transform: translateY(100px);
            transition: transform 1.5s ease-in-out;

            @include media("<desktop") {
                font-size: 65px;
            }

            @include media("<laptopXL") {
                font-size: 50px;
            }

            @include media("<laptop") {
                padding-left: 25px;
            }

            @include media("<tablet") {
                font-size: 32px;
            }
        }
    }

    .allActu {
        height: 25px;
        color: #ffffff;
        font-family: "Gilroy-Bold";
        font-size: 20px;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        padding-right: 40px;
        padding-left: 28px;

        @include media("<laptop") {
            height: 100px;
            align-items: center;
        }

        @include media("<tablet") {
            height: 80px;
        }

        @include media("<mobileXL") {
            height: 43px;
            font-size: 17px;
        }

        a:first-of-type {
            display: none;
            @include media("<mobileL") {
                display: block;
            }
        }

        a {
            position: relative;
            transform: translateY(150px);
            transition: transform 2s ease-in-out;
            @include media("<mobileL") {
                display: none;
            }
            span {
                @include media("<laptop") {
                    display: none;
                }
            }

            &::before {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                border-top: 2px solid #fff;
                border-right: 2px solid #fff;
                margin: auto;
                left: -25px;
                top: 0;
                bottom: 5px;

                @include media("<laptop") {
                    width: 15px;
                    height: 15px;
                    left: -20px;
                    top: 0px;
                }

                @include media("<tablet") {
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                }
            }

            &::after {
                content: "";
                position: absolute;
                width: 30px;
                height: 2px;
                background-color: #fff;
                border-radius: 1px;
                margin: auto;
                top: 0;
                bottom: 5px;
                left: -33px;
                transform: rotate(-45deg) translateX(-6px);
                transition: all 0.3s ease-in;

                @include media("<laptop") {
                    width: 20px;
                    left: -23px;
                    top: 0px;
                    transform: rotate(-45deg) translateX(-3px);
                }

                @include media("<tablet") {
                    height: 3px;
                }
            }

            &:hover {
                &::after {
                    transform: rotate(-45deg) translateX(0px) scaleX(0.6);
                }
            }
        }
    }

    .slider {
        max-width: 80vw;
        margin: auto;
        overflow: hidden;
        position: relative;
        z-index: 1;

        @include media("<laptop") {
            max-width: 100%;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 45px;
            @include scrimGradient($primary, "to right");
            z-index: 1;

            @include media("<laptopL") {
                width: 35px;
            }

            @include media("<laptop") {
                display: none;
            }
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
            transform: rotate(180deg);
        }

        & > a:first-child {
            transform: translateY(-140%);
            width: 30%;
            height: 100%;
            padding: 0;
        }

        ul {
            display: flex;
            padding: 0 20px;

            @include media("<desktop") {
                margin-top: 50px;
            }

            @include media("<laptopXL") {
                margin-top: 30px;
            }

            @include media("<laptopL") {
                padding: 10px;
                margin-top: 20px;
            }

            @include media("<laptop") {
                padding: 20px;
            }

            @include media("<mobileS") {
                padding: 10px;
            }

            li {
                position: relative;
                flex: 0 0 (100% / 3);
                padding: 0 20px;

                @include media("<laptopL") {
                    padding: 10px;
                }

                @include media("<laptop") {
                    flex: 0 0 50%;

                    span {
                        display: block;
                        position: absolute;
                        width: 57px;
                        height: 3px;
                        background-color: #e3e3e3;
                        z-index: 1;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 20px;
                    }
                }

                @include media("<tablet") {
                    flex: 0 0 100%;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    transform: translateY(-200%);
                    transition: all 2s;

                    .cardContent {
                        height: 100%;
                        transition: all 2s;

                        .cardTitle,
                        .cardDate {
                            opacity: 0;
                            transform: translateY(-20px);
                            transition: all 2s;
                        }

                        // .bgNetwork {
                        //     transform: scale(0);
                        //     transition: all 1s;
                        //     width: 40%;
                        // }

                        .cardText {
                            p > div {
                                opacity: 0;
                            }
                        }
                    }
                }

                article {
                    position: relative;

                    figure {
                        height: 630px;

                        @include media("<desktopL") {
                            height: 600px;
                        }

                        @include media("<desktop") {
                            height: 420px;
                            padding-bottom: 150px;
                        }

                        @include media("<laptop") {
                            padding-bottom: 0;
                        }

                        @include media("<mobileS") {
                            height: 355px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .cardContent {
                    background-color: white;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 20px;
                    color: $primary;
                    height: 45%;
                    transition: all 1s ease-in-out;

                    @include media("<laptopXL") {
                        height: 50%;
                    }

                    @include media("<laptop") {
                        padding: 50px 20px;
                        transition: all 0.5s ease-in-out;
                    }

                    @include media("<tablet") {
                        height: 100%;
                    }

                    .cardTitle {
                        font-family: Gilroy-Bold;
                        font-size: 50px;
                        height: 100px !important;

                        @include media("<desktopXL") {
                            font-size: 45px;
                        }

                        @include media("<desktopL") {
                            font-size: 24px;
                            height: 45px !important;
                        }

                        @include media("<laptopXL") {
                            font-size: 24px;
                            height: 48px !important;
                        }

                        @include media("<laptop") {
                            font-size: 35px;
                            height: 80px !important;
                        }

                        @include media("<mobileS") {
                            font-size: 30px;
                            height: 63px !important;
                        }
                    }

                    .cardDate {
                        font-family: Gilroy-Bold;
                        font-size: 15px;
                        padding: 15px 0 5px;

                        @include media("<laptopM") {
                            padding: 10px 0 5px;
                            font-size: 12px;
                        }

                        @include media("<tablet") {
                            padding: 10px 0 15px;
                        }

                        @include media("<mobileXL") {
                            padding: 15px 0;
                        }

                        @include media("<mobileS") {
                            padding: 10px 0;
                        }
                    }

                    .cardText {
                        font-size: 18px;
                        overflow: hidden;
                        height: 85px;

                        @include media("<desktopXL") {
                            height: 62px;
                        }

                        @include media("<desktop") {
                            font-size: 14px;
                            height: 48px;
                        }

                        @include media("<laptopL") {
                            height: 48px;
                        }

                        @include media("<laptop") {
                            font-size: 17px;
                            height: 49%;
                        }

                        @include media("<tablet") {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    .network {
                        border-radius: 25px;
                        padding: 10px 15px;
                        width: max-content;
                        display: flex;
                        position: absolute;
                        bottom: 10px;
                        transition: all 1s ease-in-out;
                        right: 12px;

                        @include media("<desktop") {
                            padding: 5px 10px;
                        }

                        @include media("<laptop") {
                            padding: 8px 20px;
                            right: 20px;
                        }

                        a {
                            &:not(:first-child) {
                                margin: 0 3px;
                                width: 20px;
                                height: 20px;
                                z-index: 1;

                                @include media("<desktop") {
                                    width: 15px;
                                    height: 15px;
                                }

                                @include media("<laptop") {
                                    width: 25px;
                                    height: 25px;
                                }
                            }

                            svg {
                                height: 20px;
                                width: 20px;

                                .st0 {
                                    fill: $primary;
                                    stroke: $primary;
                                }

                                @include media("<desktop") {
                                    width: 15px;
                                    height: 15px;
                                }

                                @include media("<laptop") {
                                    width: 25px;
                                    height: 25px;
                                }
                            }
                        }

                        span {
                            background-color: $primary;
                            position: absolute;
                            z-index: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            margin: auto;
                            width: 100%;
                            height: auto;
                            border-radius: 25px;
                        }
                    }
                }
            }

            .inActif {
                position: absolute;
                width: 80%;
                padding: 0px;
                right: 10px;
                bottom: 40px;

                @for $i from 1 through 8 {
                    &:nth-child(#{$i}) {
                        z-index: (8 - $i);
                    }
                }
            }

            .actif {
                z-index: 10;

                img {
                    box-shadow: 1px 0 5px #262422;
                }
            }
        }
    }

    .cardReseaux {
        padding-left: 6%;
        a {
            padding-right: 5px;
        }
        padding-top: 10%;
    }

    .nav {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        width: max-content;
        opacity: 0;
        transform: translateY(-150%);
        transition: all 1s;

        @include media("<laptop") {
            display: none;
        }

        img {
            margin: 30px 10px;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }

        .navArrow {
            position: relative;
            width: 40px;
            height: 40px;
            border: 2px solid #fff;
            border-radius: 50%;
            transition: all 0.3s;
            margin-top: 30px;

            @include media("<laptopL") {
                margin-top: 15px;
            }

            &.prev {
                margin-right: 25px;
                transform: rotate(180deg);
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: -6px;
                bottom: 0;
                width: 12px;
                height: 12px;
                margin: auto;
                border: 2px solid #fff;
                border-left: none;
                border-bottom: none;
                transform: rotate(45deg);
                transition: 0.2s border-color;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0px;
                width: 20px;
                height: 2px;
                margin: auto;
                background-color: #fff;
            }

            &:disabled {
                filter: brightness(0.5);
            }

            &:not(:disabled) {
                &:hover {
                    &:nth-child(1) {
                        transform: rotate(180deg) scale(1.1);
                    }

                    &:nth-child(2) {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
