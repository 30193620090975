#main-tolefi-finance {
    height: 100vh;

    #slide-finance {
        height: 100vh;
        background-color: #fff;
        padding-top: 10vh;
        position: relative;

        > ul {
            height: 100%;
            display: flex;

            > li {
                height: 100%;
                flex: 0 0 100%;
                padding: 60px 90px;
                overflow: hidden;

                ul {
                    color: $blue;
                    margin-top: 50px;
                    width: 80%;

                    li {
                        &:first-child {
                            border-top: 2px solid $blue;
                        }

                        padding: 15px 5px;
                        border-bottom: 2px solid $blue;
                        font-family: "Gilroy-Bold";
                        font-size: 20px;
                        position: relative;
                        cursor: pointer;
                        background: linear-gradient(to left, #fff 50%, $blue 50%) right;
                        background-size: 202%;
                        transition: 0.3s ease;

                        > a,
                        > div {
                            overflow: hidden;
                            background: linear-gradient(to right, #fff, #fff 50%, $blue 50%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-size: 200% 100%;
                            background-position: 100%;
                            transition: background-position 0.3s ease;
                        }

                        &:hover {
                            background-position: left !important;
                            > a,
                            > div {
                                background-position: 0 100% !important;
                            }

                            &:not(.noArrow) {
                                &::before {
                                    border-top: 2px solid #fff;
                                    border-right: 2px solid #fff;
                                }

                                &::after {
                                    background-color: #fff;
                                }
                            }
                        }
                        &:not(.noArrow) {
                            &::before {
                                content: "";
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border-top: 2px solid $blue;
                                border-right: 2px solid $blue;
                                margin: auto;
                                right: 5px;
                                top: 0;
                                bottom: 0;
                                transition: all 0.3s ease-in;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 2px;
                                background-color: $blue;
                                border-radius: 1px;
                                margin: auto;
                                top: -6px;
                                bottom: 0;
                                right: -1px;
                                transform: rotate(-45deg) translateX(-6px);
                                transition: all 0.3s ease-in;

                                @include media("<tablet") {
                                    height: 2px;
                                    width: 20px;
                                    top: -5px;
                                }
                            }
                        }
                    }

                    .noArrow {
                        cursor: pointer;
                        padding: 0;

                        a {
                            padding: 15px 5px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 20px;

                            @include media("<laptopXL") {
                                font-size: 18px;
                            }

                            @include media("<tablet") {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            h2 {
                color: $blue;
                font-size: 62px;
                line-height: 70px;
                margin-bottom: 50px;

                @include media("<laptopXL") {
                    margin-bottom: 25px;
                }
            }

            p {
                font-size: 14px;
                line-height: 25px;
                text-align: justify;

                @include media("<desktop") {
                    font-size: 14px;
                    line-height: 28px;
                }
            }

            .slide1 {
                display: flex;

                > div {
                    &:first-child {
                        flex: 30%;
                        padding-right: 40px;
                        margin: 0 50px;
                        align-self: center;

                        @include media("<desktop") {
                            flex: 40%;
                        }
                    }

                    &:last-child {
                        flex: 70%;
                        display: flex;
                        justify-content: space-between;

                        @include media("<desktop") {
                            flex: 60%;
                        }
                    }

                    figure {
                        margin: 0;
                        width: 48%;
                        transition: all 0.3s;
                        cursor: pointer;

                        &:hover {
                            transform: translateY(-20px) !important;
                        }

                        img {
                            height: 1000px;
                            width: 800px;
                            object-fit: cover;

                            @include media("<desktop") {
                                height: 750px;
                                width: 800px;
                            }

                            @include media("<laptopXL") {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }

                    h2 {
                        animation: fadeTitleMetier 1s;
                    }

                    p {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            background-color: #fff;
                            bottom: 0;
                            opacity: 0;
                            animation: fadeParaMetier 3s;
                        }
                    }

                    .index {
                        animation: fadeUlMetier 1.5s;
                        overflow: hidden;

                        li {
                            white-space: nowrap;

                            span {
                                position: absolute;
                                width: 0;
                                height: 100%;
                                background-color: #fff;
                                margin: auto;
                                right: 0;
                                top: 0;
                                z-index: 1;
                                animation: fadeUlLiMetier 3s;
                            }
                        }
                    }

                    figure {
                        overflow: hidden;
                        animation: fadeFigureMetier 2s;
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
            }

            .slide2,
            .slide3 {
                display: flex;

                > div:first-child {
                    figure {
                        position: absolute;
                        top: -60px;
                        left: -90px;
                        margin: 0;
                        height: 125%;
                        width: 100%;
                        overflow: hidden;
                        opacity: 0;

                        img {
                            height: 100%;
                            width: 1500px;
                            object-fit: cover;
                            max-width: initial;
                            transform: translateX(-30%);
                        }
                    }
                }

                > div {
                    position: relative;

                    &:first-child {
                        flex: 47%;
                    }

                    &:last-child {
                        flex: 53%;
                        padding: 0 40px;
                        align-self: center;

                        @include media("<desktop") {
                            padding: 0 10px 0 0;
                        }
                    }

                    .content2 {
                        width: 70%;
                        margin: auto;

                        @include media("<desktop") {
                            width: 90%;
                        }
                    }

                    .content2,
                    .content3 {
                        > ul {
                            overflow: hidden;

                            li {
                                white-space: nowrap;

                                span {
                                    position: absolute;
                                    width: 0;
                                    height: 100%;
                                    background-color: #fff;
                                    margin: auto;
                                    right: 0;
                                    top: 0;
                                    z-index: 1;
                                }
                            }
                        }

                        p {
                            position: relative;

                            span {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-color: #fff;
                                margin: auto;
                                right: 0;
                                bottom: 0;
                                opacity: 0;
                                z-index: 1;
                                animation: fadeParaMetier2 5s;
                            }
                        }
                    }
                }
            }

            .slide3 {
                .content3 {
                    width: 80%;
                    margin: auto;

                    ul {
                        width: 100%;
                    }
                    @include media("<desktop") {
                        width: 70%;
                    }
                }
            }
        }

        .pagination {
            display: none;

            button {
                width: 70px;
                height: 70px;
                border: 2px solid $blue;
                border-radius: 50%;
                transition: all 0.5s;
                position: absolute;
                top: 0;
                bottom: 0;

                &::before {
                    content: "";
                    position: absolute;
                    width: 22px;
                    height: 2px;
                    left: 22px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background-color: $blue;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    right: 22px;
                    transform: rotate(45deg);
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    border-top: 2px solid $blue;
                    border-right: 2px solid $blue;
                }
            }

            .slidePrev {
                margin: auto auto auto 0;
                transform: rotate(180deg);
                left: 80px;

                @include media("<laptopXL") {
                    left: 60px;
                }

                &:disabled {
                    transform: translateX(-250%) rotate(180deg);
                }

                &:hover {
                    transform: scale(1.5) rotate(180deg);
                }
            }

            .slideNext {
                margin: auto 0 auto auto;
                right: 80px;

                @include media("<laptopXL") {
                    right: 60px;
                }

                &:disabled {
                    transform: translateX(250%);
                }

                &:not(:disabled) {
                    &:hover {
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
}
