#main-tolefi-distribution {
    height: 100vh;

    #slide-distribution {
        height: 100vh;
        background-color: #fff;
        padding-top: 10vh;
        position: relative;

        > ul {
            height: 100%;
            display: flex;

            > li {
                height: 100%;
                flex: 0 0 100%;
                padding: 60px 90px;
                overflow: hidden;

                .list {
                    color: $blue;
                    margin-top: 40px;
                    width: 100%;

                    li {
                        &:first-child {
                            border-top: 2px solid $blue;
                        }

                        padding: 15px 5px;
                        border-bottom: 2px solid $blue;
                        font-family: "Gilroy-Bold";
                        font-size: 20px;
                        position: relative;
                        cursor: pointer;
                        background: linear-gradient(to left, #fff 50%, $blue 50%) right;
                        background-size: 202%;
                        transition: 0.3s ease;

                        > a,
                        > div {
                            overflow: hidden;
                            background: linear-gradient(to right, #fff, #fff 50%, $blue 50%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-size: 200% 100%;
                            background-position: 100%;
                            transition: background-position 0.3s ease;
                        }

                        &:hover {
                            background-position: left !important;
                            > a,
                            > div {
                                background-position: 0 100% !important;
                            }

                            &:not(.noArrow) {
                                &::before {
                                    border-top: 2px solid #fff;
                                    border-right: 2px solid #fff;
                                }

                                &::after {
                                    background-color: #fff;
                                }
                            }
                        }
                        &:not(.noArrow) {
                            &::before {
                                content: "";
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border-top: 2px solid $blue;
                                border-right: 2px solid $blue;
                                margin: auto;
                                right: 5px;
                                top: 0;
                                bottom: 0;
                                transition: all 0.3s ease-in;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 2px;
                                background-color: $blue;
                                border-radius: 1px;
                                margin: auto;
                                top: -6px;
                                bottom: 0;
                                right: -1px;
                                transform: rotate(-45deg) translateX(-6px);
                                transition: all 0.3s ease-in;

                                @include media("<tablet") {
                                    height: 2px;
                                    width: 20px;
                                    top: -5px;
                                }
                            }
                        }
                    }

                    .noArrow {
                        cursor: pointer;
                        padding: 0;

                        a {
                            padding: 15px 5px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 20px;

                            @include media("<laptopXL") {
                                font-size: 18px;
                            }

                            @include media("<tablet") {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            h2 {
                color: $blue;
                font-size: 62px;
                line-height: 70px;
                margin-bottom: 50px;

                @include media("<laptopXL") {
                    font-size: 58px;
                    line-height: 60px;
                    margin-bottom: 25px;
                }
            }

            p {
                font-size: 14px;
                line-height: 25px;
                text-align: justify;

                @include media("<desktop") {
                    font-size: 14px;
                    line-height: 28px;
                }

                @include media("<laptopXL") {
                    font-size: 13px;
                    line-height: 22px;
                }
            }

            .slide1 {
                display: flex;

                > div {
                    &:first-child {
                        flex: 30%;
                        padding-right: 40px;
                        margin-left: 100px;
                        align-self: center;

                        @include media("<desktop") {
                            flex: 40%;
                            margin-left: 0;
                        }
                    }

                    &:last-child {
                        flex: 70%;
                        display: flex;
                        justify-content: space-between;
                        position: relative;

                        @include media("<desktop") {
                            flex: 60%;
                        }

                        figure {
                            position: relative;

                            img {
                                position: absolute;
                                width: auto;
                                height: 70%;
                                // height: auto;
                                margin: auto;
                                top: 90px;
                                bottom: 0;
                                transition: top 0.3s;
                                object-position: 80%;
                                border-radius: 70% 30%;
                            }

                            &:nth-child(1) {
                                right: -190px;
                                bottom: 90px;

                                @include media("<desktop") {
                                    right: -80px;
                                    bottom: 70px;
                                }
                            }

                            &:nth-child(3) {
                                left: -90px;
                                bottom: 90px;

                                @include media("<desktop") {
                                    left: -50px;
                                    bottom: 70px;
                                }
                            }
                        }
                    }

                    figure {
                        margin: 0;
                        width: 48%;

                        overflow: hidden;
                        animation: fadeFigureMetier 2s;
                        margin-right: 0;
                        margin-left: auto;
                        transition: transform 0.3s;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            cursor: pointer;
                        }

                        &:nth-child(1) {
                            &:hover {
                                transform: scale(1.1);
                            }

                            img {
                                animation: bounceY 5s infinite 3s;
                            }
                        }

                        &:nth-child(2) {
                            &:hover {
                                transform: scale(1.1);
                            }

                            img {
                                animation: bounceY2 5s infinite 3s;
                            }
                        }

                        &:nth-child(3) {
                            &:hover {
                                transform: scale(1.1);
                            }

                            img {
                                animation: bounceY 5s infinite 3s;
                            }
                        }
                    }

                    h2 {
                        animation: fadeTitleMetier 1s;
                    }

                    p {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            background-color: #fff;
                            bottom: 0;
                            opacity: 0;
                            animation: fadeParaMetier 3s;
                        }
                    }

                    .index {
                        animation: fadeUlMetier 1.5s;
                        overflow: hidden;

                        li {
                            white-space: nowrap;

                            span {
                                position: absolute;
                                width: 0;
                                height: 100%;
                                background-color: #fff;
                                margin: auto;
                                right: 0;
                                top: 0;
                                z-index: 1;
                                animation: fadeUlLiMetier 3s;
                            }
                        }
                    }
                }
            }

            .slide2 {
                display: flex;

                @include media("<laptopXL") {
                    padding-left: 70px;
                    padding-right: 70px;
                }

                > div {
                    position: relative;

                    &:first-child {
                        flex: 55%;
                        padding: 0 40px;
                        align-self: center;

                        @include media("<desktop") {
                            padding: 0 10px 0 0;
                        }

                        @include media("<laptopXL") {
                            flex: 58%;
                        }
                    }

                    &:last-child {
                        flex: 45%;

                        @include media("<laptopXL") {
                            flex: 42%;
                        }

                        figure {
                            img {
                                position: absolute;
                                width: 90%;
                                margin-top: -60px;
                                height: 120%;
                                object-fit: cover;
                                object-position: 79%;

                                @include media("<laptopXL") {
                                    height: 125%;
                                    // margin-left: 70px;
                                    width: 85%;
                                }
                            }
                        }
                    }

                    .content2 {
                        .list {
                            margin-top: 20px;
                            width: 100%;
                        }

                        @include media("<desktop") {
                            width: 90%;
                            margin-left: auto;
                        }
                    }
                }

                > div {
                    .content2 {
                        opacity: 0;
                        > ul {
                            overflow: hidden;

                            li {
                                white-space: nowrap;

                                span {
                                    position: absolute;
                                    width: 0;
                                    height: 100%;
                                    background-color: #fff;
                                    margin: auto;
                                    right: 0;
                                    top: 0;
                                    z-index: 1;
                                }
                            }
                        }

                        .divparaDistribution {
                            position: relative;

                            span {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-color: #fff;
                                margin: auto;
                                right: 0;
                                bottom: 0;
                                opacity: 0;
                                z-index: 1;
                                animation: fadeParaMetier2 5s;
                            }
                        }
                    }

                    &:last-child {
                        figure {
                            position: absolute;
                            top: -60px;
                            left: 90px;
                            margin: 0;
                            height: 125%;
                            width: 100%;
                            overflow: hidden;
                            opacity: 0;

                            img {
                                width: 100%;
                                object-fit: cover;
                                max-width: initial;
                                // transform: translateX(-30%);
                            }
                        }
                    }
                }
            }

            .slide3 {
                display: flex;

                > div {
                    position: relative;

                    &:first-child {
                        flex: 55%;

                        @include media("<desktop") {
                            flex: 50%;
                        }

                        figure {
                            img {
                                position: absolute;
                                width: 100%;
                                margin-top: -60px;
                                margin-left: -90px;
                                height: 120%;
                                object-fit: cover;

                                @include media("<laptopXL") {
                                    height: 125%;
                                }
                            }
                        }
                    }

                    &:last-child {
                        flex: 45%;
                        padding: 0 40px;
                        align-self: center;

                        @include media("<desktop") {
                            padding: 0 10px 0 0;
                            flex: 50%;
                        }
                    }

                    .content2 {
                        @include media("<desktop") {
                            width: 90%;
                        }
                    }
                }

                > div:first-child {
                    figure {
                        position: absolute;
                        top: -60px;
                        left: -90px;
                        margin: 0;
                        height: 125%;
                        width: 100%;
                        overflow: hidden;
                        opacity: 0;

                        img {
                            width: 1150px;
                            object-fit: cover;
                            max-width: initial;
                            transform: translateX(-9%);
                        }
                    }
                }

                .content2 {
                    @include media("<laptopXL") {
                        h2 {
                            font-size: 52px;
                            line-height: 54px;
                        }
                        p {
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                    > ul {
                        overflow: hidden;

                        li {
                            white-space: nowrap;

                            span {
                                position: absolute;
                                width: 0;
                                height: 100%;
                                background-color: #fff;
                                margin: auto;
                                right: 0;
                                top: 0;
                                z-index: 1;
                            }
                        }
                    }

                    .divparaDistribution2 {
                        position: relative;

                        span {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            margin: auto;
                            right: 0;
                            bottom: 0;
                            opacity: 0;
                            z-index: 1;
                            animation: fadeParaMetier2 5s;
                        }
                    }
                }
            }

            .slide4 {
                display: flex;

                > div {
                    position: relative;

                    &:first-child {
                        flex: 40%;
                        padding: 0 40px;
                        align-self: center;
                    }

                    &:last-child {
                        flex: 60%;

                        figure {
                            img {
                                position: absolute;
                                width: 100%;
                                margin-top: -60px;
                                margin-left: 90px;
                                height: 120%;
                                object-fit: cover;

                                @include media("<laptopXL") {
                                    height: 125%;
                                }
                            }
                        }

                        @include media("<desktop") {
                            padding: 0 10px 0 0;
                        }
                    }
                }

                > div:last-child {
                    figure {
                        position: absolute;
                        top: -60px;
                        left: 90px;
                        margin: 0;
                        height: 125%;
                        width: 100%;
                        overflow: hidden;
                        opacity: 0;

                        img {
                            height: 105%;
                            width: 1400px;
                            object-fit: cover;
                            max-width: initial;
                            transform: translateX(-30%);

                            @include media("<desktop") {
                                width: 1050px;
                            }

                            @include media("<laptopXL") {
                                width: 900px;
                            }
                        }
                    }
                }

                .content2 {
                    margin-left: 50px;
                    opacity: 0;

                    @include media("<desktop") {
                        width: 90%;
                    }
                    > ul {
                        overflow: hidden;
                        width: 100%;

                        li {
                            white-space: nowrap;

                            span {
                                position: absolute;
                                width: 0;
                                height: 100%;
                                background-color: #fff;
                                margin: auto;
                                right: 0;
                                top: 0;
                                z-index: 1;
                            }
                        }
                    }

                    p {
                        position: relative;

                        span {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            margin: auto;
                            right: 0;
                            bottom: 0;
                            opacity: 0;
                            z-index: 1;
                            animation: fadeParaMetier2 5s;
                        }
                    }
                }
            }
        }

        .pagination {
            display: none;

            button {
                width: 70px;
                height: 70px;
                border: 2px solid $blue;
                border-radius: 50%;
                transition: all 0.5s;
                position: absolute;
                top: 0;
                bottom: 0;

                &::before {
                    content: "";
                    position: absolute;
                    width: 22px;
                    height: 2px;
                    left: 22px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background-color: $blue;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    right: 22px;
                    transform: rotate(45deg);
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    border-top: 2px solid $blue;
                    border-right: 2px solid $blue;
                }
            }

            .slidePrev {
                margin: auto auto auto 0;
                transform: rotate(180deg);
                left: 80px;

                @include media("<laptopXL") {
                    left: 60px;
                }

                &:disabled {
                    transform: translateX(-250%) rotate(180deg);
                }

                &:hover {
                    transform: scale(1.5) rotate(180deg);
                }
            }

            .slideNext {
                margin: auto 0 auto auto;
                right: 80px;

                @include media("<laptopXL") {
                    right: 60px;
                }

                &:disabled {
                    transform: translateX(250%);
                }

                &:not(:disabled) {
                    &:hover {
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
}
