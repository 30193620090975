.tolefi-video {
    justify-content: center;
    top: 0;

    h2 {
        font-size: 70px;
        max-width: 500px;
        margin: 0;
        line-height: 70px;
        opacity: 0;

        > div > div {
            opacity: 0;
        }
    }

    .divPara {
        margin: 50px 0;
        height: 210px;

        @include media("<laptopXL") {
            height: 180px;
        }

        @include media("<tablet") {
            height: 220px;
        }

        p {
            font-size: 16px;
            line-height: 26px;
            text-align: justify;
            overflow: hidden;
            height: 0;

            @include media("<desktop") {
                font-size: 14px;
                line-height: 22px;
            }

            @include media("<tablet") {
                margin: 35px 0;
                width: initial;
            }
        }
    }

    .linkMobile {
        overflow: hidden;
        position: relative;

        span {
            display: inline-block;
        }
    }

    a,
    .linkMobile {
        margin: 0;
        // border: none;
        // border-radius: 0;
        outline-offset: -1px;

        border-radius: 50px;
        border: 1px solid #fff;
        opacity: 0;
        // transition: opacity 1s;

        // span {
        //     &:nth-child(1) {
        //         transform: translateX(-150px);
        //     }
        // }

        // > svg {
        //     transform: translateY(-50px);
        // }

        .c1,
        .c2 {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                background: #fff;
            }
        }

        .c1 {
            &::before {
                left: 0;
                right: 0;
                height: 1px;
                transform-origin: 0 0;
                top: 0;
                animation: width 1s;
            }

            &::after {
                left: 0;
                right: 0;
                height: 1px;
                transform-origin: 0 0;
                bottom: 0;
                animation: width2 1s;
            }
        }

        .c2 {
            &::before {
                top: 0;
                bottom: 0;
                width: 1px;
                transform-origin: 0 100%;
                right: 0;
                animation: height 1s;
            }

            &::after {
                top: 0;
                bottom: 0;
                width: 1px;
                transform-origin: 0 100%;
                left: 0;
                animation: height2 0.5s;
            }
        }
    }

    @include media("<mobileXL") {
        h2 {
            font-size: 37px;
            max-width: 260px;
            line-height: 40px;
        }
    }

    @include media(">=desktop") {
        h2 {
            font-size: 100px;
            line-height: 100px;
            max-width: 800px;
        }
    }

    .linkDesktop {
        position: relative;
        overflow: hidden;
        transition: background-color 0.3s, opacity 0.5s;
        width: 150px;

        &:hover {
            background-color: #fff;

            span {
                &:nth-child(1) {
                    transform: translateY(-40px) !important;
                }

                &:nth-child(2) {
                    transform: translateY(0);
                }
            }

            path {
                fill: $primary;
            }
        }

        span {
            transition: transform 0.3s;
            position: absolute;

            &:nth-child(2) {
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                padding-left: 20px;
                height: fit-content;
                transform: translateY(40px);
                color: $primary;
            }
        }

        svg {
            float: right;
            right: 0;
            margin: 0 12px 0;
            transition: all 0.3s;
        }
    }
}

@include media("<mobileXL") {
    .immo-wrapper-text {
        width: 100%;

        .text-immo {
            padding: 15px 20px;
            justify-content: flex-start;
        }
    }
}
