// Colors
$primary: #262422;
$secondary: #161515;
$beige: #f1f0ef;
$grey: #6d6f77;
$blue: #294b7e;
$blue2: #3f6e88;
$link: rgb(255, 83, 83);

// Sizes
$maxWidth: 1265px;
$gridMargin: 90px;
$gridGap: 1.2%;
$padding: 20px;

// Timing functions
$quint: cubic-bezier(0.7, 0, 0.3, 1);
$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutQuad: cubic-bezier(0.46, 0.03, 0.52, 0.96);

// Breakpoints
$breakpoints: (
	mobileS: 325px,
	mobileM: 375px,
	mobileL: 425px,
	mobileXL: 508px,
	tablet: 768px,
	laptop: 1025px,
	laptopM: 1195px,
	laptopL: 1350px,
	laptopXL: 1440px,
	desktop: 1600px,
	desktopL: 1920px,
	desktopXL: 2100px,
	desktopXXL: 2400px,
);
