#header {
    position: fixed;
    z-index: 99999;
    width: 100%;
    transition: all 1s;
    height: 10vh;

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 60px 20px 60px;
        animation-duration: 6s;
        margin: auto;
        left: 0;
        right: 0;
        color: white;
        height: 100%;

        @include media("<laptop") {
            padding: 20px;
        }

        div,
        a {
            width: 50px;
        }

        .burger {
            cursor: pointer;

            svg {
                color: rgb(226, 226, 226);
                height: 15px;
                width: 15px;
                fill: rgb(238, 238, 238);

                .cls-1 {
                    stroke-linecap: square;
                }

                @include media("<laptop") {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .language {
            font-family: Gilroy-Light;
            transition: color 0.5s;
           cursor: pointer; 

            @include media("<laptop") {
                font-size: 18px;
            }
        }

        .langueActive {
            font-family: Gilroy-Bold;
        }

        .logo {
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;

            @include media("<laptopM") {
                right: 0;
            }

            svg {
                width: 30px;

                @include media("<laptop") {
                    width: 40px;
                }

                path {
                    stroke: transparent;
                }
            }
        }
    }

    .contact {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 45%;
        transition: all 1s;
        animation: fadeHeaderContact 6s;
        padding-left: 40px;

        @include media("<desktop") {
            padding-left: 5px;
        }

        @include media("<laptop") {
            display: none;
        }

        a {
            padding: 6px 20px;

            svg {
                height: 15px;
                width: 15px;
            }
        }
    }

    .borderLineHead {
        position: absolute;
        bottom: 0;
        margin: auto;
        left: 60px;
        right: 60px;
        transition: opacity 0.5s, transform 1s;
        animation: borderLineHead 7s;
        opacity: 1;
        border-bottom: 2px solid #fff;

        @include media("<laptop") {
            left: 20px;
            right: 20px;
        }
    }
}

.inverted {
    @include media("<tablet") {
        background-color: $beige;
    }

    .language {
        color: $primary;
    }

    .cls-1,
    .st0 {
        fill: $primary;
        stroke: $primary;
    }

    .borderLineHead {
        border-bottom: 2px solid $primary !important;
    }
}

.invertedBlue {
    .language {
        color: $blue;
    }

    .cls-1,
    .st0 {
        fill: $blue;
        stroke: $blue;
    }

    .borderLineHead {
        border-bottom: 2px solid $blue !important;
    }
}
