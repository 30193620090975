#sectionHome {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;

  @include media("<laptopM") {
    flex-direction: column;
    height: 100vh;
  }

  .divTitleIntro {
    z-index: 1;

    #homeTitle {
      text-align: center;
      position: absolute;
      color: #ffffff;
      font-size: 100px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      height: min-content;

      @include media("<laptopL") {
        font-size: 80px;
      }
      @include media("<laptopM") {
        font-size: 80px;
      }
      @include media("<laptop") {
        font-size: 42px;
      }
      @include media("<mobileXL") {
        font-size: 50px;
        bottom: 10%;
      }
      @include media("<mobileS") {
        font-size: 35px;
      }
      .borderWriter {
        animation-duration: 0.5s;
        -webkit-animation-duration: 1s;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        width: 30px;
        height: 5px;
        position: absolute;
        bottom: 20px;
      }
    }
    .divSubTitle {
      position: absolute;
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: auto;
      bottom: 200px;
      top: 0;
      height: max-content;

      @include media("<laptopM") {
        position: relative;
        flex-direction: column;
        bottom: 0;
      }

      .subTitlePara {
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        line-height: 0;
        text-align: center;
        width: (100% / 3);

        position: relative;

        @include media("<laptopM") {
          width: 100%;
          font-size: 16px;
        }

        @include media("<mobileS") {
          font-size: 14px;
        }

        &:nth-child(1) {
          @include media("<laptopM") {
            top: 28vh;
          }
          @include media("<tablet") {
            top: 25vh;
          }
          span {
            right: 30px;
            @include media("<laptopM") {
              left: 0;
              right: 0;
              //bottom: 650px;
              //top: -298px;
              margin: auto;
              height: max-content;
            }
            @include media("<laptop") {
              //top: -154px;
            }
            @include media("<925px") {
              //top: -298px;
            }
            @include media("<tablet") {
              //top: -204px;
            }

            @include media("<mobileXL") {
              //bottom: 500px;
            }
            // @include media("<428px") {
            //   border: 2px solid red;
            // }
            @include media("<mobileL") {
              // bottom: 545px;
              //top: -214px;
            }
            @include media("<=mobileM") {
              // bottom: 500px;
              //top: -183px;
            }

            @include media("<mobileS") {
              //bottom: 410px;
            }
          }
        }
        &:nth-child(2) {
          @include media("<laptopM") {
            top: 61vh;
          }
          @include media("<tablet") {
            top: 55vh;
          }
          span {
            left: 0;
            right: 0;
            margin: auto;

            @include media("<laptopM") {
              left: 0;
              right: 0;
              //bottom: 0;
              //top: 130px;
              margin: auto;
              height: max-content;
            }

            @include media("<laptop") {
              //top: 100px;
            }
            @include media("<tablet") {
              //top: 48px;
            }

            @include media("<mobileXL") {
              //top: 85px;
            }

            @include media("<mobileL") {
              //top: 45px;
            }

            @include media("<mobileS") {
              //top: 35px;
            }
          }
        }
        &:nth-child(3) {
          @include media("<laptopM") {
            top: 72vh;
          }
          @include media("<tablet") {
            top: 65vh;
          }
          span {
            left: 30px;

            @include media("<laptopM") {
              left: 0;
              right: 0;
              //bottom: 0;
              //top: 650px;
              margin: auto;
              height: max-content;
            }

            @include media("<mobileXL") {
              //top: 455px;
            }

            @include media("<mobileL") {
              //top: 510px;

              @media only screen and (max-height: 770px) {
                //top: 430px;
              }
            }

            @include media("<mobileS") {
              //top: 430px;
            }
          }
        }
        span {
          display: block;
          transition: all 0.5s;
          cursor: pointer;
          position: absolute;
          min-width: max-content;
          &:nth-child(2) {
            transform: matrix(1, 0, 0, 0.7, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.01, 0, 0, 1, 0, 0, 0, 0, 1)
              translateY(15px) scaleY(0);
            color: $link;
          }
        }
        &:hover {
          span {
            &:first-child {
              transform: matrix(1, 0, 0, 0.7, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, -0.01, 0, 0, 1, 0, 0, 0, 0, 1)
                translateY(-15px) scaleY(0);
            }
            &:nth-child(2) {
              transform: matrix(1, 0, 0, 1, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
                translateY(0px) scaleY(1);
            }
          }
        }
      }
    }
    #realEstateTitle {
      animation: fadeHomeSubTitle 5s;
    }
    #financeTitle {
      animation: fadeHomeSubTitleTwo 5.4s;
    }
    #retailDistributionTitle {
      animation: fadeHomeSubTitleThree 5.8s;
    }
  }
  video {
    height: 100vh;
    object-fit: cover;
    transition: filter 0.5s;
    filter: grayscale(0.4) brightness(0.5);

    &:focus {
      filter: grayscale(0) brightness(1);
    }
  }
  .filterGrascale {
    filter: grayscale(0) brightness(1);
  }

  #firstVideo {
    width: (100% / 3);
    animation: fadeFirstVideo 2s;

    @include media("<laptopM") {
      animation: fadeFirstVideoX 2s;
      width: 100%;
      height: (100% / 3);
    }

    @include media("<tablet") {
      height: 30%;
    }
  }

  #secondVideo {
    width: (100% / 3);
    animation: fadeSecondVideo 2s;

    @include media("<laptopM") {
      width: 100%;
      height: (100% / 3);
      animation: fadeSecondVideoX 2s;
    }

    @include media("<tablet") {
      height: 30%;
    }
  }

  #thirdVideo {
    width: (100% / 3);
    animation: fadeThirdVideo 2.2s;

    @include media("<laptopM") {
      animation: fadeThirdVideoX 2s;
      width: 100%;
      height: (100% / 3);
    }

    @include media("<tablet") {
      height: 40%;
    }
  }

  #scrolldown {
    position: absolute;
    color: white;
    svg {
      color: rgb(226, 226, 226);
      height: 30px;
      width: 30px;
      fill: rgb(238, 238, 238);
    }
  }
  .divCta {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(600px);
    opacity: 0;
    transition: all 3s;
    z-index: 10;
    .ctaScroll {
      @include ctaCircle;
      animation: ctaScrollBounce 6s infinite ease-in-out;
    }
  }
}

#realEstate,
#finance,
#retailDistribution {
  position: relative;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);

  .linkDesktop {
    @include media("<laptop") {
      display: none;
    }
  }

  .linkMobile {
    padding: 10px 18px 10px 20px;
    font-size: 15px;
    margin: 0;

    @include media(">laptop") {
      display: none;
    }
  }
}

.o-hidden {
  overflow: hidden;
}

@import "./pageMetierMobile/responsiveImmobilier.scss";
@import "./pageMetierMobile/responsiveDistribution.scss";
@import "./pageMetierMobile/responsiveGroupe.scss";
