#groupe {
	height: 100vh;
	padding-top: 10vh;
	position: relative;
	overflow: hidden;

	.backgroundImg {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: all 1.5s;

		img {
			width: 100%;
			height: 90%;
			object-fit: cover;
		}
	}

	p {
		font-family: Helvetica;
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 25px;
		transition: all 1.5s;
		text-align: justify;

		@include media("<desktop") {
			font-size: 14px;
			line-height: 22px;
		}

		@include media("<tablet") {
			margin-bottom: 25px;
			margin-top: 25px;
		}
	}

	.contentPrimary {
		background-color: $primary;
		color: #fff;
		height: 100%;
		margin-left: auto;
		padding: 80px 90px 0;
		transform: translateX(100%);
		transition: all 1.5s;
		display: flex;
		align-items: center;

		@include media("<desktopL") {
			padding-top: 50px;
		}

		@include media("<desktop") {
			padding-right: 50px;
		}

		@include media("<laptop") {
			padding: 50px;
		}

		@include media("<tablet") {
			padding: 0px 20px 50px;
		}

		@include media("<mobileS") {
			padding-top: 20px;
		}

		.primary {
			flex: 35%;
			padding: 0 40px;
			overflow: hidden;
			transition: all 1s;

			@include media("<desktopL") {
				padding-left: 0;
				flex: 30%;
			}

			@include media("<laptop") {
				padding-right: 20px;
				flex: 55%;
			}

			@include media("<tablet") {
				flex: 100%;
			}

			@include media("<mobileS") {
				padding: 0;
			}

			h2 {
				font-size: 105px;
				line-height: 105px;
				padding-bottom: 90px;
				position: relative;
				padding-right: 150px;

				@include media("<desktopXL") {
					padding-right: 0;
				}

				@include media("<desktop") {
					font-size: 85px;
					line-height: 85px;
					padding-bottom: 70px;
				}

				@include media("<laptopXL") {
					font-size: 80px;
					line-height: 80px;
					padding-right: 30px;
					padding-bottom: 50px;
				}

				@include media("<laptop") {
					padding-right: 0;
				}

				@include media("<tablet") {
					font-size: 54px;
					line-height: 64px;
				}

				@include media("<=mobileM") {
					font-size: 45px;
					line-height: 55px;
					padding-bottom: 25px;
				}

				@include media("<mobileS") {
					font-size: 30px;
					line-height: 35px;
					padding-bottom: 10px;
					padding-top: 15px;
				}

				&::before {
					content: "";
					position: absolute;
					width: 90px;
					height: 2px;
					background-color: #fff;
					bottom: 55px;

					// @include media("<desktopL") {
					// 	bottom: 55px;
					// }

					@include media("<desktop") {
						width: 70px;
						bottom: 40px;
					}

					@include media("<laptopXL") {
						bottom: 30px;
					}

					@include media("<=mobileM") {
						bottom: 5px;
					}

					@include media("<mobileS") {
						bottom: -5px;
						width: 60px;
					}
				}
			}

			button {
				border: 1px solid #fff;
				border-radius: 25px;
				padding: 0px 15px;
				font-weight: 700;
				position: relative;
				display: flex;
				transition: padding 0.5s, border-radius 0.5s, margin 0.5s, background-color 0.3s;
				font-size: 12px;

				&:hover {
					background-color: #fff;

					span {
						&:nth-child(1):not(.open) {
							transform: translateY(-40px) !important;
						}

						&:nth-child(2):not(.open) {
							transform: translateY(0);
						}
					}

					svg path {
						fill: $primary;
					}
				}

				div {
					overflow: hidden;
					width: 130px;
					height: 40px;
					position: relative;
					transition: all 0.5s;
					display: flex;

					span {
						position: absolute;
						transition: all 0.3s;
						align-self: center;

						@include media("<tablet") {
							position: initial;
						}

						&:nth-child(2) {
							transform: translateY(40px);
							color: $primary;
						}
					}
				}

				svg {
					position: absolute;
					width: 15px;
					height: 15px;
					right: 20px;
					transition: all 1s;
					margin: auto;
					top: 0;
					bottom: 0;

					path {
						transition: fill 0.3s;
					}

					@include media("<desktop") {
						width: 15px;
						height: 15px;
					}
				}
			}

			.linkDesktop {
				@include media("<laptop") {
					display: none;
				}
			}

			.linkMobile {
				display: none;

				@include media("<laptop") {
					display: block;
				}
			}

			> ul {
				display: flex;
				font-family: "Gilroy-Bold";
				overflow: hidden;
				position: absolute;
				top: 340px;
				bottom: 0;
				margin: auto auto auto -30px;
				height: max-content;

				@include media("<desktopXL") {
					top: 290px;
				}

				@include media("<desktopL") {
					top: 180px;
				}

				> li {
					font-size: 55px;
					width: 115px;
					text-align: center;
					margin: 0 6px;
					position: relative;
					transition: all 0.5s;
					transform: translateY(-100%);

					@include media("<desktopL") {
						font-size: 40px;
						margin: 0;
					}

					@include media("<desktop") {
						width: 95px;
					}

					&:not(:last-child) {
						&::before {
							content: "";
							position: absolute;
							width: 3px;
							height: 45%;
							top: 0;
							bottom: 10px;
							margin: auto;
							right: -7px;
							background-color: #fff;

							@include media("<desktopL") {
								right: -2px;
							}

							@include media("<desktop") {
								width: 1px;
								right: -1px;
							}
						}
					}

					ul {
						font-size: 14px;
						padding-top: 10px;
						white-space: nowrap;

						@include media("<desktopL") {
							font-size: 12px;
						}
					}
				}
			}
		}

		.secondary {
			flex: 65%;
			display: flex;
			gap: 200px;

			@include media("<desktopXL") {
				gap: 70px;
			}

			@include media("<desktopL") {
				flex: 70%;
			}

			@include media("<desktop") {
				margin-top: -30px;
				gap: 30px;
			}

			@include media("<tablet") {
				display: none;
			}

			div {
				flex: 50%;
				overflow: hidden;

				p {
					transition: all 1s;
					transform: translateX(100%);
				}
			}

			h3 {
				font-family: "Gilroy-Bold";
				font-size: 38px;
				margin-bottom: 50px;
				position: relative;

				@include media("<desktop") {
					font-size: 32px;
				}

				&::before {
					content: "";
					position: absolute;
					width: 0px;
					height: 2px;
					background-color: #fff;
					bottom: -20px;
					transition: all 1.5s;
				}
			}

			.classBefore {
				&::before {
					width: 65px;
				}
			}
		}
	}

	.desktop {
		transform: translateY(150px);

		@include media("<laptop") {
			display: none;
		}
	}

	.mobile {
		display: none;

		@include media("<laptop") {
			display: block;
		}

		@include media("<mobileS") {
			.ctaScroll {
				bottom: 20px;
			}
		}
	}
}
