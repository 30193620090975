.right-section {
	width: 30%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	@include media("<laptop") {
		align-items: flex-start;
		width: 100%;
	}

	@include media("<tablet") {
		height: auto;
	}

	&__container {
		height: 78%;
		width: 100%;
		&__searchbar {
			position: relative;
			// display: flex;

			@include media("<laptop") {
				display: none;
			}

			input {
				width: 100%;
				background-color: #fff;
				border-radius: 25px;
				padding: 10px 20px;

				&::-webkit-input-placeholder {
					font-family: Helvetica;
					font-size: 15px;
					font-style: italic;
				}
				&::-moz-placeholder {
					font-family: Helvetica;
					font-size: 15px;
					font-style: italic;
				}
			}

			svg {
				width: 15px;
				height: 15px;
				position: absolute;
				right: 15px;
				top: 0;
				bottom: 0;
				margin: auto;

				path {
					fill: $primary;
				}
			}

			.listeSearch {
				position: absolute;
				text-align: left;
				max-height: 500px;
				border-radius: 4px;
				color: $blue;
				background: #fff;
				box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
				z-index: 9999;
				top: 45px;
				left: 0;
				right: 0;
				visibility: hidden;

				li {
					overflow: hidden;
					font-size: 17px;
					cursor: pointer;

					a {
						display: block;
						padding: 15px 40px;
					}

					&:hover {
						background-color: #f8f8f8;
					}
				}
			}
		}
		&__date {
			margin: 30px 0;
			display: flex;
			font-size: 20px;
			font-weight: 400;
			@include rightSectionSubDisplay;
			justify-content: space-between;

			& > p:nth-child(2) {
				font-family: Gilroy-Bold;
			}

			@include media("<laptop") {
				font-size: 25px;
			}

			@include media("<tablet") {
				font-size: 15px;
			}
		}
		&__address {
			border-top: 1px solid grey;
			border-bottom: 1px solid grey;
			align-items: center;
			font-size: 15px;
			padding: 30px 0;
			@include rightSectionSubDisplay;

			@include media("<laptop") {
				font-size: 20px;
				display: block;

				p:not(:first-child) {
					margin-top: 20px;
				}
			}

			@include media("<tablet") {
				font-size: 15px;
			}
		}
		&__map {
			margin-top: 50px;
			width: 100%;
			height: 50%;

			@include media("<laptop") {
				height: auto;
			}

			img {
				width: 100%;
				height: auto;

				@include media("<laptop") {
					padding-bottom: 20px;
				}
			}
		}
		&__contact {
			text-align: center;
			padding: 20px 0 50px;

			@include media(">=laptop") {
				display: none;
			}

			@include media("<tablet") {
				padding: 10px 0px 20px;
			}

			a {
				padding: 6px 10px;

				svg {
					height: 40px;
					width: 40px;

					path {
						fill: $primary;
					}
				}
			}
		}
	}
}
