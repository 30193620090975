#main-contact {
    width: 100%;
    min-height: 100vh;
    background-color: $beige;
    display: flex;
    justify-content: center;
    align-items: center;

    // @include media("<tablet") {
    //     background-color: #fff;
    // }

    .contact {
        background-color: #fff;
        // margin-bottom: 50px;
        position: absolute;

        @include media("<desktop") {
            margin-top: 70px;
        }

        @include media("<tablet") {
            margin-top: 10vh;
            overflow: hidden;
        }

        > div {
            padding: 40px 40px 0;

            @include media("<tablet") {
                padding: 20px 20px 0;
            }
        }

        h2 {
            color: $primary;
            font-size: 50px;
            line-height: 58px;
            padding: 0 10px;
            margin-bottom: 35px;
            opacity: 0;

            @include media("<desktop") {
                font-size: 40px;
                margin-bottom: 15px;
            }

            @include media("<laptopXL") {
                font-size: 32px;
                line-height: 32px;
            }

            @include media("<tablet") {
                font-size: 26px;
                line-height: 26px;
            }

            @include media("<mobileL") {
                font-size: 22px;
                line-height: 22px;
            }
        }

        .wpcf7 {
            margin-bottom: 80px;

            & form {
                & p {
                    display: inline-block;
                    width: calc(50% - 4px);
                    padding: 0 10px;

                    @include media("<desktop") {
                        padding-top: 20px;
                    }

                    @include media("<=mobileL") {
                        width: 100%;
                        padding-top: 5px;
                    }
                }
                & p:nth-child(6) {
                    width: 100%;
                }
                & p:nth-child(7) {
                    width: 100%;
                    position: relative;
                }

                & p:nth-child(8) {
                    width: max-content;
                    display: grid;
                    position: relative;
                    margin: auto;
                    padding: 0;
                    margin-top: 30px;
                    color: $primary;
                    transition: color 0.3s;

                    &:hover {
                        color: #fff;

                        &::before {
                            border-top: 2px solid #fff;
                            border-right: 2px solid #fff;
                        }

                        &::after {
                            background-color: #fff;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-top: 2px solid $primary;
                        border-right: 2px solid $primary;
                        margin: auto;
                        right: 20px;
                        top: 0;
                        transition: all 0.3s;
                        bottom: 5px;
                        z-index: 1;

                        @include media("<tablet") {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 18px;
                        height: 2px;
                        background-color: $primary;
                        border-radius: 1px;
                        margin: auto;
                        top: 0;
                        bottom: 8px;
                        right: 15px;
                        transform: rotate(-45deg) translateX(-6px);
                        transition: all 0.3s;

                        @include media("<tablet") {
                            height: 2px;
                            width: 20px;
                            top: -5px;
                        }
                    }
                }

                & input {
                    margin: 0px 0 30px 0;
                    box-sizing: border-box;
                    width: 100%;
                    height: 40px;
                    transition: all 0.3s;

                    @include media("<desktop") {
                        margin-bottom: 0px;
                        height: 20px;
                    }

                    @include media("<=mobileL") {
                        margin: 10px 0;
                    }
                }

                & textarea {
                    margin-top: 10px;
                    width: 100%;
                    height: 120px;

                    @include media("<mobileL") {
                        height: 90px;
                    }
                }

                & input,
                & textarea {
                    border-bottom: 1px $primary solid;
                }

                & label {
                    color: $primary;
                    font-family: Helvetica;
                    font-size: 15px;
                    line-height: 20px;
                    position: relative;

                    & > span:first-child {
                        position: absolute;
                        bottom: 15px;
                        transition: all 0.3s;

                        @include media("<desktop") {
                            bottom: 15px;
                        }
                    }

                    @include media("<mobileL") {
                        font-size: 12px;
                        line-height: 12px;
                    }

                    &:focus-within > span:first-child {
                        bottom: 25px;
                    }
                }

                & input[type="submit"] {
                    border: 1px solid $primary;
                    width: 135px;
                    height: 39px;
                    border-radius: 28px;
                    padding-left: 16px;
                    font-size: 15px;
                    font-family: "Gilroy-Bold";
                    transition: background-color 0.3s;
                    position: relative;
                    margin: 0;

                    &:hover {
                        background-color: $primary;
                    }
                }

                .submitText_1 {
                    position: absolute;
                    padding-left: 17px;
                    font-size: 15px;
                    font-family: "Gilroy-Bold";
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: fit-content;
                }

                .wpcf7-spinner {
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        .formRecrutement {
            p:nth-child(6),
            p:nth-child(7) {
                width: calc(50% - 10px) !important;
            }

            p:nth-child(7) {
                span:first-child {
                    bottom: 32px;
                }
                input {
                    border: none;
                    height: 28px;
                    margin-bottom: 10px;
                }
            }

            p:nth-child(8) {
                width: 100% !important;
                padding: 10px 10px 20px 10px !important;
                margin-top: 0 !important;

                &::before,
                &::after {
                    content: none !important;
                }
            }

            & p:nth-child(9) {
                width: max-content;
                display: grid;
                position: relative;
                margin: auto;
                padding: 0;
                transition: all 0.3s ease-in;

                &::before {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-top: 2px solid $primary;
                    border-right: 2px solid $primary;
                    margin: auto;
                    right: 20px;
                    top: 0;
                    bottom: 0px;
                    z-index: 1;
                    transition: all 0.3s ease-in;

                    @include media("<tablet") {
                        width: 15px;
                        height: 15px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 2px;
                    background-color: $primary;
                    border-radius: 1px;
                    margin: auto;
                    top: 0;
                    bottom: 5px;
                    right: 15px;
                    transform: rotate(-45deg) translateX(-6px);
                    transition: all 0.3s ease-in;

                    @include media("<tablet") {
                        height: 2px;
                        width: 20px;
                        top: -5px;
                    }
                }

                &:hover {
                    color: #fff;

                    &::before {
                        border-top: 2px solid #fff;
                        border-right: 2px solid #fff;
                    }

                    &::after {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}
