.text-fi {
    padding: 0;

    h2 {
        max-width: initial;
    }
    @include media("<mobileXL") {
        h2 {
            line-height: 50px;
        }
        .h2-finance {
            font-size: 31px;
            line-height: 36px;
        }
    }
    @include media(">laptopL") {
        width: 60%;
        left: -16%;
    }
}
