#actualites,
#recrutements {
	position: relative;
	padding-top: 150px;

	.main {
		@include gridLayout;
		max-width: 1600px;

		@include media("<laptop") {
			max-width: 100%;
		}
		padding: 0;

		@include media("<tablet") {
			padding: 0;
		}

		h1 {
			font-family: "Gilroy-Bold";
			font-size: 100px;
			color: #fff;
			grid-row: 1;
			grid-column: 1 / 23;
			padding-left: 20px;

			@include media("<laptop") {
				font-size: 68px;
			}

			@include media("<tablet") {
				font-size: 30px;
				padding: 0 20px;
			}
		}

		@import "./allActus.scss";
	}
}
