.organigrammeDesktop {
    max-width: 1400px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 75px 0;
    text-align: center;
    position: relative;
    min-height: 100vh;
    padding-right: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    opacity: 0;

    @include media("<desktop") {
        max-width: 1000px;
    }

    .parentdekstop {
        margin: auto;
        top: 0;
        bottom: 0;
        height: max-content;
        width: 100%;

        svg {
            width: 100%;

            .st4 {
                stroke-width: 2px;
            }
        }
        // Style for organigramme SVG
        .immobilierTitleBorderHiding {
            @include borderHiding(50px, -293px);
        }
        .financeTitleBorderHiding {
            @include borderHiding(600px, -293px);
        }
        .distributionTitleBorderHiding {
            @include borderHiding(1161px, -293px);
        }

        .immobilierTitle,
        .financeTitle,
        .distributionTitle {
            transform: translateY(-50px);
            stroke: #262422;
        }
        .dekstop {
            width: 100%;
            height: auto;

            @include media("<laptop") {
                display: none;
            }
        }
    }

    @include media("<mobileXL") {
        display: none;
    }

    video {
        width: 100%;
        height: auto;
    }
}

#organigrammeParent {
    height: 100vh;
    position: relative;
    padding-top: 50px;

    @include media(">=mobileXL") {
        display: none;
    }

    @include media("<mobileS") {
        .ctaScroll {
            bottom: 20px;
        }
    }
}

#organigramme {
    position: relative !important;
    opacity: 0;
    overflow: hidden;
    margin: auto;
    max-width: 375px;
    max-height: 653px;

    @include media("<mobileS") {
        overflow: hidden;
    }

    @include media(">mobileS") {
        overflow: hidden;

        .ctaScroll {
            cursor: pointer;
            z-index: 20;
        }
    }
}

#return {
    display: none;
    cursor: pointer;
    &:before {
        transform: rotate(90deg);
        top: 22px;
        left: -2px;
    }
    &:after {
        transform: rotate(40deg);
    }
}

#first-circle,
#second-circle,
#third-circle {
    position: absolute;
    border: 2px solid white;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    z-index: 5;
    cursor: pointer;
    @include media("<mobileXL") {
        z-index: 1;
        width: 100px;
        height: 100px;
        border: 1px solid white;
    }
}

#first-circle {
    z-index: 999;
}

.first-row > span,
.second-row > span,
.third-row > span,
.fourth-row > span,
.fifth-row > span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    @include media("<mobileXL") {
        font-size: 15px;
        z-index: 5;
    }
}

.section-distribution {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    position: absolute;
    color: white;
    text-align: center;
    width: 300px;
    height: 200px;
    border-radius: 50%;
    text-transform: uppercase;
    opacity: 0;

    @include media(">mobileM") {
        font-size: 1rem;
        left: 9%;
        top: 18%;
    }
    @include media("<=mobileM") {
        font-size: 1rem;
        left: 11%;
        top: 18%;
    }
    @include media("<=mobileS") {
        font-size: 1rem;
        top: 19%;
        left: -3%;
    }
}

.section-immobilier {
    @include subtitle(20%);
    @include media(">mobileM") {
        font-size: 1rem;
        left: 10%;
        bottom: 28%;
    }
    @include media("<=mobileM") {
        font-size: 1rem;
        left: 10%;
        bottom: 28%;
    }
    @include media("<=mobileS") {
        font-size: 1rem;
        left: -3%;
        bottom: 28%;
    }
}

.section-finance {
    @include subtitle(58%);
    @include media(">mobileM") {
        font-size: 1rem;
        position: absolute;
        left: 9%;
        bottom: 7%;
    }
    @include media("<=mobileM") {
        font-size: 1rem;
        left: 9%;
        bottom: 7%;
    }
    @include media("<=mobileS") {
        font-size: 1rem;
        position: absolute;
        left: -4%;
        bottom: 7%;
    }
}

#tolefi {
    position: absolute;
    color: white;
    font-size: 50px;
    @include media("<mobileXL") {
        font-size: 30px;
        text-transform: uppercase;
        font-family: "Gilroy-Bold";
    }
}

.border-left {
    @include display-border(21%, 23%, 19%);
}

.border-right {
    @include display-border-right(21%, 23%, 19%);
}

.border-middle-left {
    transform: rotate(90deg);
    @include display-border(25%, 10%, 39%);
    z-index: 990;
    @include media("<mobileS") {
        @include display-border(26%, 22%, 140%);
        &::before {
            position: absolute;
            background-color: $primary;
            content: "";
            width: 140%;
            height: 70px;
        }
    }
    @include media("<=mobileM") {
        @include display-border(26%, 22%, 74%);
        &::before {
            position: absolute;
            background-color: $primary;
            content: "";
            width: 120%;
            height: 200px;
        }
    }
    @include media(">mobileM") {
        @include display-border(26%, 22%, 103%);
        &::before {
            position: absolute;
            background-color: $primary;
            content: "";
            width: 120%;
            height: 200px;
        }
    }
    @include media("<mobileS") {
        @include display-border(26%, 22%, 101%);
    }
}
.border-middle-middle {
    transform: rotate(90deg);
    @include display-border(25%, 50%, 5%);
}
.border-middle-right {
    transform: rotate(90deg);
    z-index: 20000;
    @include display-border-right(25%, -15%, 24%);
    @include media("<mobileS") {
        @include display-border(26%, 22%, 87%);
        &::before {
            position: absolute;
            background-color: $primary;
            z-index: 10000;
            content: "";
            width: 140%;
            height: 70px;
        }
    }

    @include media(">mobileM") {
        @include display-border(26%, 22%, 64%);
        &::before {
            position: absolute;
            background-color: $primary;
            z-index: 10000;
            content: "";
            width: 140%;
            height: 200px;
        }
    }
    @include media("<=mobileM") {
        @include display-border(25%, 21%, 43%);
        &::before {
            position: absolute;
            background-color: $primary;
            z-index: 10000;
            content: "";
            width: 140%;
            height: 200px;
        }
    }
    @include media("<mobileS") {
        @include display-border(26%, 21%, 54%);
        &::before {
            position: absolute;
            background-color: $primary;
            z-index: 10000;
            content: "";
            width: 140%;
            height: 200px;
        }
    }
}

.structure > div > span {
    @include media("<mobileXL") {
        transform: translateX(-400%);
    }
    font-family: "Gilroy-Bold";
}

.little-border {
    @include media("<mobileXL") {
        transform: translateX(-400%);
    }
}

.structure {
    width: 80%;
    height: 70%;
    position: absolute;
    bottom: 0;
    & > .first-row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 20%;
        & > span:first-child {
            @include display-subsection(1.8%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: 49%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: 8%;
                transform: translateX(-400%);
            }
        }
        & > span:nth-child(2) {
            background-color: transparent;
            @include display-subsection(26%);
            @include media("<mobileXL") {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                cursor: pointer;
            }
            @include media("<=mobileM") {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                cursor: pointer;
            }
            @include media("<=mobileS") {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                cursor: pointer;
            }
        }
        & > span:nth-child(3) {
            background-color: transparent;
            @include display-subsection(56%);
            @include media("<mobileXL") {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                cursor: pointer;
            }
            @include media("<=mobileM") {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                cursor: pointer;
            }
            @include media("<=mobileS") {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                cursor: pointer;
            }
        }
        & > span:nth-child(4) {
            @include display-subsection-right(0.2%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: 49%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: 4%;
                transform: translateX(-400%);
            }
        }
    }
    & > .second-row {
        @include display-row;
        & > span:first-child {
            @include display-subsection(1.8%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: 27%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: -35%;
                transform: translateX(-400%);
            }
        }
        & > span:nth-child(2) {
            @include display-subsection(29.5%);
            @include media("<mobileXL") {
                left: 26%;
                top: -49%;
            }
            @include media("<=mobileM") {
                height: 30px;
                left: 26%;
                top: -93%;
            }
        }
        & > span:nth-child(3) {
            @include display-subsection(53%);
            @include media("<mobileXL") {
                left: 26%;
                top: -50%;
            }
            @include media("<=mobileM") {
                height: 30px;
                top: -94%;
            }
        }
        & > span:nth-child(4) {
            @include display-subsection-right(0.2%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: 27%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: -35%;
                transform: translateX(-400%);
            }
        }
    }
    & > .third-row {
        @include display-row;
        & > span:first-child {
            @include display-subsection(1.8%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: 13%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: -75%;
                transform: translateX(-400%);
            }
        }
        & > span:nth-child(2) {
            @include display-subsection(29.5%);
            @include media("<mobileXL") {
                left: 26%;
                top: -72%;
            }
            @include media("<=mobileM") {
                height: 30px;
                left: 26%;
                top: -136%;
            }
        }
        & > span:nth-child(3) {
            @include display-subsection(53%);
            @include media("<mobileXL") {
                left: 26%;
                top: -72%;
            }
            @include media("<=mobileM") {
                height: 30px;
                top: -135%;
            }
        }
        & > span:nth-child(4) {
            @include display-subsection-right(0.2%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: 13%;
                transform: translateX(-400%);
                text-align: center;
                font-size: 12px;
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: -78%;
                transform: translateX(-400%);
            }
        }
    }
    & > .fourth-row {
        @include display-row;
        & > span:first-child {
            @include display-subsection(1.8%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: -6%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: -107%;
                transform: translateX(-400%);
            }
        }
        & > span:nth-child(2) {
            @include display-subsection(29.5%);
            @include media("<mobileXL") {
                // height: 30px;
                left: 26%;
                top: -89%;
            }
            @include media("<=mobileM") {
                left: 26%;
                top: -177%;
            }
        }
        & > span:nth-child(3) {
            @include display-subsection(53%);
            @include media("<mobileXL") {
                left: 26%;
                top: -89%;
            }
            @include media("<=mobileM") {
                height: 30px;
                top: -177%;
            }
        }
    }
    & > .fifth-row {
        @include display-row;
        & > span:first-child {
            @include display-subsection(1.8%);
            @include media("<mobileXL") {
                @include display-subsection(24.8%);
                top: -32%;
                transform: translateX(-400%);
            }
            @include media("<=mobileM") {
                @include display-subsection(24.8%);
                height: 30px;
                top: -136%;
                transform: translateX(-400%);
            }
        }
        & > span:nth-child(2) {
            @include display-subsection(53%);
            @include media("<mobileXL") {
                left: 26%;
                top: -107%;
            }
            @include media("<=mobileM") {
                height: 30px;
                top: -211%;
            }
        }
    }
}

.border-middle-middle-left,
.border-middle-middle-right {
    border: 1px solid white;
    transform: rotate(90deg);
    @include media("<mobileXL") {
        opacity: 0;
    }
}

.border-middle-middle-left {
    width: 21%;
    position: absolute;
    left: 32%;
    top: 44%;
    @include media("<mobileXL") {
        opacity: 0;
    }
}

.border-middle-middle-right {
    width: 29%;
    position: absolute;
    right: 1%;
    bottom: 56%;
}

// Display little Border
/* First column */
.border-left-first-row {
    @include display-little-border-left(41%);
    @include media("<mobileXL") {
        transform: translateX(-400%);
        @include display-little-border-left(40%);
    }
    @include media("<=mobileM") {
        transform: translateX(-400%);
        @include display-little-border-left(33%);
    }
}
.border-left-second-row {
    @include display-little-border-left(55%);
    @include media("<mobileXL") {
        @include display-little-border-left(51%);
        transform: translateX(-400%);
    }
    @include media("<=mobileM") {
        transform: translateX(-400%);
        @include display-little-border-left(41%);
    }
}
.border-left-third-row {
    @include display-little-border-left(69%);
    @include media("<mobileXL") {
        @include display-little-border-left(63%);
        transform: translateX(-400%);
    }
    @include media("<=mobileM") {
        transform: translateX(-400%);
        @include display-little-border-left(49%);
    }
}
.border-left-fourth-row {
    @include display-little-border-left(83%);
    @include media("<mobileXL") {
        @include display-little-border-left(74%);
        transform: translateX(-400%);
    }
    @include media("<=mobileM") {
        transform: translateX(-400%);
        @include display-little-border-left(59%);
    }
}
.border-left-fifth-row {
    @include display-little-border-left(97%);
    @include media("<mobileXL") {
        @include display-little-border-left(85%);
        transform: translateX(-400%);
    }
    @include media("<=mobileM") {
        transform: translateX(-400%);
        @include display-little-border-left(69%);
    }
}

/* Second Column */

.border-middle-first-row-left {
    @include display-little-border-middle-left(55%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-middle-second-row-left {
    @include display-little-border-middle-left(69%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-middle-third-row-left {
    @include display-little-border-middle-left(83%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}

.border-middle-middle-bottom-left {
    @include display-little-border-middle-bottom(40%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-middle-middle-bottom-right {
    @include display-little-border-middle-bottom(50%);
    width: 11%;
    @include media("<mobileXL") {
        opacity: 0;
    }
}

.border-middle-middle-bottom-left-bottom {
    @include display-little-border-middle-bottom-right-left(40%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}

.border-middle-middle-bottom-right-bottom {
    @include display-little-border-middle-bottom-right-left(61%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}

/* Third column */

.border-middle-first-row-right {
    @include display-little-border-middle-right(55%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-middle-second-row-right {
    @include display-little-border-middle-right(69%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-middle-third-row-right {
    @include display-little-border-middle-right(83%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-middle-fourth-row-right {
    @include display-little-border-middle-right(97%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}

/* Fourth column */

.border-right-first-row {
    @include display-little-border-mliddle-right(41%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-right-second-row {
    @include display-little-border-mliddle-right(55%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}
.border-right-third-row {
    @include display-little-border-mliddle-right(69%);
    @include media("<mobileXL") {
        opacity: 0;
    }
}

.structure span {
    z-index: 1;
    background-color: #262422;
}
