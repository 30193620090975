.middle-section {
	position: relative;
	display: flex;
	width: 34%;
	height: 100%;
	flex-direction: column;
	justify-content: flex-end;
	font-family: Gilroy-Bold;

	@include media("<desktop") {
		width: 28%;
	}

	@include media("<laptop") {
		display: none;
	}

	&__more {
		&--display {
			display: flex;
			flex-direction: column;
		}
	}
	&__plus {
		opacity: 0; 
		height: 80%;
		padding-top: 120px;

		p {
			width: 12px;
			height: 26px;
			margin: 15px 0 32px;
			left: 50px;
			z-index: 1;
			position: relative;
			cursor: pointer;

			svg {
				border: 1px solid #545251;
				border-radius: 5px;
				width: 12px;
				height: 0.1px;
				position: absolute;
				top: -2px;
				bottom: 0;
				margin: auto;

				&:nth-child(2) {
					transform: rotate(90deg);
					transition: transform 200ms;
				}
			}
		}
	}
	&__content {
		@include display-content(372px);
	}
	&__contentone {
		@include display-content(442px);
	}
	&__contenttwo {
		@include display-content(490px, 5px);
	}
	&__contentthree {
		@include display-content(564px, 10px);
	}

	.level2Link {
		// border: 2px solid red; 
		position: relative;
		transform: translateX(-190px); 
		& > button > p {
			display: none; 
		}

		a {
			display: block;
			transition: all 0.5s;
			min-width: max-content;

			&:nth-child(1) {
				width: max-content;
			}
			&:nth-child(2) {
				position: absolute;
				top: 0px;
				transform: matrix(1, 0, 0, 0.7, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.01, 0, 0, 1, 0, 0, 0, 0, 1)
					translateY(10px) scaleY(0);
				color: $link;
			}
		}
		&:hover {
			a {
				&:first-child {
					transform: matrix(1, 0, 0, 0.7, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, -0.01, 0, 0, 1, 0, 0, 0, 0, 1)
						translateY(-15px) scaleY(0);
				}
				&:nth-child(2) {
					transform: matrix(1, 0, 0, 1, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
						translateY(0px) scaleY(1);
				}
			}
		}
	}
}
