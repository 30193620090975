#grpRight {
	background-color: $primary;
	height: 100vh;
	display: flex;
	position: fixed;
	z-index: 999;
	width: 100%;
	transform: translateX(100%);
	transition: transform 1s cubic-bezier(0, 0, 0.2, 1);
	top: 0;
	color: #fff;

	@include media(">laptop") {
		display: none;
	}

	.slider {
		max-width: 80vw;
		position: relative;

		@include media("<laptop") {
			max-width: 100%;
		}

		.slidePage {
			display: flex;
			height: 100%;

			.slideDistri {
				padding-bottom: 50px;
			}

			> li {
				padding: 0 50px;
				flex: 0 0 100%;
				overflow-y: scroll;
				overflow-x: hidden;

				@include media("<tablet") {
					padding: 0 20px;
				}

				h2 {
					margin-bottom: 30px;
					font-size: 64px;
					line-height: 70px;
					color: #fff;

					@include media("<tablet") {
						font-size: 32px;
						line-height: 40px;
					}

					@include media("<mobileS") {
						font-size: 28px;
						line-height: 34px;
						margin-bottom: 15px;
					}
				}

				h3 {
					font-family: "Gilroy-Bold";
					font-size: 45px;
					margin: 70px 0;
					position: relative;

					@include media("<tablet") {
						font-size: 26px;
						margin: 50px 0;
					}

					@include media("<mobileS") {
						font-size: 18px;
						margin: 10px 0 30px;
					}

					&::before {
						content: "";
						position: absolute;
						width: 0px;
						height: 2px;
						background-color: #fff;
						bottom: -20px;
						transition: all 1.5s;

						@include media("<mobileS") {
							bottom: -15px;
						}
					}
				}

				.classBefore {
					&::before {
						width: 75px;

						@include media("<tablet") {
							width: 45px;
						}

						@include media("<mobileS") {
							width: 30px;
						}
					}
				}

				p {
					font-family: Helvetica;
					font-size: 20px;
					line-height: 30px;
					width: 100%;
					margin: 0;
					transition: all 1s;
					transform: translateX(108%);
					text-align: justify;

					@include media("<tablet") {
						font-size: 14px;
						line-height: 20px;
					}

					@include media("<mobileS") {
						font-size: 12px;
					}
				}

				> ul {
					display: flex;
					font-family: "Gilroy-Bold";
					margin: 50px 0;
					overflow: hidden;
					justify-content: space-between;

					@include media("<mobileS") {
						margin: 30px 0;
					}

					> li {
						font-size: 85px;
						text-align: center;
						position: relative;
						transition: all 0.5s;
						transform: translateY(-108%);
						width: auto;
						margin: 0;

						@include media("<tablet") {
							font-size: 44px;
						}

						@include media("<mobileS") {
							font-size: 24px;
						}

						&:not(:last-child) {
							&::before {
								content: "";
								position: absolute;
								width: 2px;
								height: 45%;
								top: 0;
								bottom: 10px;
								margin: auto;
								right: -55px;
								background-color: #fff;

								@include media("<tablet") {
									width: 1px;
									right: -20px;
								}

								@include media("<mobileS") {
									right: -8px;
									bottom: 20px;
								}
							}
						}

						ul {
							font-size: 14px;
							padding-top: 10px;
							white-space: nowrap;

							@include media("<tablet") {
								font-size: 10px;
							}

							@include media("<mobileS") {
								white-space: inherit;
								max-width: 55px;
								height: 30px;

								li {
									height: 100%;
									display: flex;
									align-items: center;
								}
							}
						}
					}
				}

				.cta {
					position: initial;
					padding: 15px;
					transform: rotate(90deg);
					width: max-content;
					margin: auto auto 20px;

					.ctaScroll {
						position: initial;
						width: 60px;
						height: 60px;

						@include media("<tablet") {
							width: 50px;
							height: 50px;
						}

						&::before {
							top: 0.8em;
							bottom: 0.8em;
						}

						&::after {
							bottom: 0.8em;
						}
					}
				}
			}
		}
	}

	.nav {
		display: none;
	}

	#grpCard {
		overflow-y: scroll;
		overflow-x: hidden;

		> ul {
			align-items: initial;
			padding: 10vh 0 0;

			li {
				> p {
					padding-bottom: 30px;
				}
			}
		}
	}
}
