.allNews {
	grid-row: 2;
	grid-column: 1 / 23;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 90px 0;
	padding: 0 20px;
	gap: 0px 60px;

	@include media("<laptop") {
		grid-template-columns: repeat(2, 1fr);
		margin-top: 220px;
	}

	@include media("<tablet") {
		margin-top: 140px;
		padding: 0;
		gap: 0 20px;
	}

	.slideActu {
		position: relative;
		flex: 0 0 (100% / 3);
		overflow: hidden;
		height: fit-content;

		@include media(">=laptop") {
			&:nth-child(3n + 2) {
				margin-top: 50px;
			}
		}

		@include media("<laptop") {
			&:nth-child(2n) {
				margin-top: 50px;
			}
			&:nth-child(2n + 1) {
				margin-top: -100px;
			}
		}

		@include media("<tablet") {
			&:nth-child(2n) {
				margin-top: 20px;
			}
			&:nth-child(2n + 1) {
				margin-top: -70px;
			}
		}

		&:hover,
		&:focus {
			.cardContent {
				transform: translateY(0%);
			}
		}

		@include media("<laptop") {
			flex: 0 0 50%;

			span {
				display: block;
				position: absolute;
				width: 57px;
				height: 3px;
				background-color: #e3e3e3;
				z-index: 1;
				left: 0;
				right: 0;
				margin: auto;
				top: 20px;
			}
		}

		@include media("<tablet") {
			flex: 0 0 100%;
		}

		article {
			position: relative;

			figure {
				height: 565px;

				@include media("<tablet") {
					height: 270px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.cardContent {
				background-color: white;
				position: absolute;
				bottom: 0;
				width: 100%;
				padding: 20px;
				color: $primary;
				height: 50%;
				transition: all 0.5s ease-in-out;
				transform: translateY(100%);
				box-shadow: 0 10px 15px black;

				// @include media(">=desktopXL") {
				// 	height: 32%;
				// }

				@include media("<laptop") {
					height: 100%;
					padding: 50px 20px;
					transition: all 1s ease-in-out;
				}

				@include media("<tablet") {
					padding: 40px 13px;
				}

				.cardTitle {
					font-family: Gilroy-Bold;
					font-size: 34px;
					//height: 64px !important;

					@include media("<laptopXL") {
						font-size: 24px;
						//height: 48px !important;
					}

					@include media("<laptop") {
						font-size: 35px;
						//height: 80px !important;
					}

					@include media("<tablet") {
						font-size: 24px;
						//height: 55px !important;
					}

					@include media("<mobileS") {
						font-size: 30px;
						//height: 63px !important;
					}
				}

				.cardDate {
					font-family: Gilroy-Bold;
					font-size: 15px;
					padding: 15px 0 10px;

					@include media("<laptopM") {
						padding: 10px 0 5px;
						font-size: 12px;
					}

					@include media("<tablet") {
						padding: 10px 0 15px;
					}

					@include media("<mobileXL") {
						padding: 15px 0 5px;
					}

					@include media("<mobileS") {
						padding: 10px 0;
					}
				}

				.cardText {
					font-size: 18px;
					overflow: hidden;
					height: 62px;

					@include media("<desktopXL") {
						height: 62px;
					}

					@include media("<desktop") {
						font-size: 14px;
					}

					@include media("<laptop") {
						font-size: 17px;
						height: 50%;
					}

					@include media("<tablet") {
						font-size: 14px;
						line-height: 20px;
					}
				}

				.network {
					border-radius: 25px;
					padding: 10px 15px;
					width: max-content;
					display: flex;
					position: absolute;
					bottom: 10px;
					transition: all 1s ease-in-out;
					right: 12px;

					@include media("<desktop") {
						padding: 5px 10px;
					}

					@include media("<laptop") {
						padding: 8px 20px;
						right: 20px;
					}

					@include media("<tablet") {
						padding: 5px 10px;
						right: 10px;
					}

					a {
						&:not(:first-child) {
							margin: 0 3px;
							width: 20px;
							height: 20px;
							z-index: 1;

							@include media("<desktop") {
								width: 15px;
								height: 15px;
							}

							@include media("<laptop") {
								width: 25px;
								height: 25px;
							}

							@include media("<tablet") {
								width: 15px;
								height: 15px;
							}
						}

						svg {
							height: 20px;
							width: 20px;

							.st0 {
								fill: $primary;
								stroke: $primary;
							}

							@include media("<desktop") {
								width: 15px;
								height: 15px;
							}

							@include media("<laptop") {
								width: 25px;
								height: 25px;
							}

							@include media("<tablet") {
								width: 15px;
								height: 15px;
							}
						}
					}

					span {
						background-color: $primary;
						position: absolute;
						z-index: 0;
						top: 0;
						bottom: 0;
						right: 0;
						left: 0;
						margin: auto;
						width: 100%;
						height: auto;
						border-radius: 25px;
					}
				}
			}
		}
	}
}
