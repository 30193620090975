.left-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 35%;
	height: 100%;
	position: relative;
	font-family: Gilroy-Bold;

	@include media("<desktop") {
		width: 39%;
	}

	@include media("<laptop") {
		width: 100%;
		justify-content: flex-start;
		height: auto;
	}

	&__plus {
		&--Home{
			opacity: 0; 
		}
		&--Tolefi{
			opacity: 0; 
		}
		&--News{
			opacity: 0; 
		}
	}

	&__text {
		width: 100%;
		height: 80%;
		&--accueil {
			& > div > button > :nth-child(3){
				opacity: 0;
			}
		}
		&--tolefi-groupe {
			& > div > button > :nth-child(3){
				opacity: 0;
			}
		}
		&--actualités {
			& > div > button > :nth-child(3){
				opacity: 0;
			}
		}
		&--contact {
			& > div > button > :nth-child(3){
				opacity: 0;
			}
		}

		@include media("<laptop") {
			height: 100%;
			margin-bottom: 70px;
		}

		@include media("<tablet") {
			margin-bottom: 30px;
		}

		.rotate {
			&::after {
				transition: all 0.3s;
				transform: rotate(90deg);
			}
		}

		> li {
			position: relative;
			text-align: right;
			line-height: 1.5em;
			font-size: 40px;
			color: $primary;
			overflow: hidden;
			transition: height 0.3s;

			@include media("<laptop") {
				line-height: 80px;
				height: 80px;
			}

			@include media("<tablet") {
				font-size: 30px;
				line-height: 65px;
				height: 60px;
			}

			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				font-size: 37px;

				@include media("<tablet") {
					font-size: 27px;
				}
			}

			&:nth-child(3) {
				color: $grey;

				@include media("<laptop") {
					padding-right: 40px;
					@include cssPlus;
				}
			}

			&:nth-child(4) {
				color: $grey;

				@include media("<laptop") {
					padding-right: 40px;
					@include cssPlus;
				}
			}

			&:nth-child(5) {
				color: $grey;

				@include media("<laptop") {
					padding-right: 40px;
					@include cssPlus;
				}
			}

			&:nth-child(6) {
				@include media("<laptop") {
					padding-right: 40px;
					@include cssPlus;
				}
			}

			.levelMobileWrapper {
				font-family: Helvetica;
				color: $primary;
				font-size: 25px;
				font-weight: 400;
				position: absolute;
				right: 0;

				@include media(">=laptop") {
					display: none;
				}

				@include media("<tablet") {
					font-size: 18px;
				}

				li {
					line-height: 30px;

					@include media("<tablet") {
						font-size: 16px;
						line-height: 25px;
					}
				}
			}

			& > a > button > a:nth-child(2) {
				top: -5px;
			}

			& > button > a:nth-child(2) {
				// top: 5px;
				top: 0px; // to keep the text aligned in the animation on the words
			}

			.level1Link {
				position: relative;
				display: flex; 
				justify-content: flex-end;
				button {
					display: flex; 
					// justify-content: center;
					align-items: center;
				}

				a {
					display: block;
					transition: all 0.5s;
					min-width: max-content;

					&:nth-child(1) {
						width: max-content;
						margin: auto 0 auto auto;
					}
					&:nth-child(2) {
						position: absolute;
						// right: 0;
						transform: matrix(1, 0, 0, 0.7, 0, 0)
							matrix3d(1, 0, 0, 0, 0, 1, 0, 0.01, 0, 0, 1, 0, 0, 0, 0, 1) translateY(20px) scaleY(0);
						color: $link;
					}
				}
				&:hover {
					a {
						&:first-child {
							transform: matrix(1, 0, 0, 0.7, 0, 0)
								matrix3d(1, 0, 0, 0, 0, 1, 0, -0.01, 0, 0, 1, 0, 0, 0, 0, 1) translateY(-25px) scaleY(0);

							@include media("<tablet") {
								transform: matrix(1, 0, 0, 0.7, 0, 0)
									matrix3d(1, 0, 0, 0, 0, 1, 0, -0.01, 0, 0, 1, 0, 0, 0, 0, 1) translateY(-30px)
									scaleY(0);
							}
						}
						&:nth-child(2) {
							transform: matrix(1, 0, 0, 1, 0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
								translateY(0px) scaleY(1);

							@include media("<tablet") {
								transform: matrix(1, 0, 0, 1, 0, 0)
									matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) translateY(-7px) scaleY(1);
							}
						}
					}
				}
				p {
					position: relative; 
					// border: 2px solid green; 
					width: 24px;
					height: 26px;
					display: flex; 
					justify-content: center; 
					margin-left: 10px; 
					z-index: 1;
					cursor: pointer;
					@include media("<laptop"){
						display: none;
					}
					svg {
						border: 1px solid #545251;
						border-radius: 5px;
						width: 12px;
						height: 0.1px;
						position: absolute;
						top: -2px;
						bottom: 0;
						margin: auto;
		
						&:nth-child(2) {
							transform: rotate(90deg);
							transition: transform 200ms;
						}
					}

				}
			}

			.displayPhone {
				display: none;

				@include media("<laptop") {
					display: initial;
				}
			}

			.displayDesktop {
				@include media("<laptop") {
					display: none;
				}
			}
		}
	}
}
