ul {
    list-style: none;
}

p {
    max-width: 100%;
}

.d-flex {
    display: flex;
}

.jc-center {
    justify-content: center;
}

.jc-space-evenly {
    justify-content: space-evenly;
}

.jc-end {
    justify-content: flex-end;
}

.jc-start {
    justify-content: flex-start;
}

.jc-space-between {
    justify-content: space-between;
}

.jc-space-around {
    justify-content: space-around;
}

.jc-stretch {
    justify-content: stretch;
}

.ai-center {
    align-items: center;
}

.ai-start {
    align-items: flex-start;
}

.ai-end {
    align-items: flex-end;
}

.as-end {
    align-self: flex-end;
}

.fd-row {
    flex-direction: row;
}

.fd-column {
    flex-direction: column;
}

.pst-relative {
    position: relative;
}

.pst-absolut {
    position: absolute;
}

.pst-fixed {
    position: fixed;
}

.zi-top {
    z-index: 100000;
}

.top-80-px {
    top: 80px;
}

.top--80-px {
    top: -80px;
}

.top-5-prc {
    top: 5%;
}

.top-15-prc {
    top: 15%;
}

.top-7_5-prc {
    top: 7.5%;
}

.top-50-prc {
    top: 50%;
    transform: translateY(-50%);
}

.bottom-0 {
    bottom: 0;
}

.bottom--10-px {
    bottom: -10px;
}

.bottom-10-prc {
    bottom: 10%;
}

.left-50-prc {
    left: 50%;
}

.trsf-trans-x-y--50-prc {
    transform: translate(-50%, -50%);
}

.mrg-0 {
    margin: 0;
}

.mrg-top-30-px {
    margin-top: 30px;
}

.mrg-top-50-px {
    margin-top: 50px;
}

.mrg-bottom-5-px {
    margin-bottom: 5px;
}

.mrg-bottom-20-px {
    margin-bottom: 20px;
}

.mrg-bottom-50-px {
    margin-bottom: 50px;
}

.mrg-left-10-px {
    margin-left: 10px;
}

.mrg-left-13-px {
    margin-left: 13px;
}

.mrg-left-16-px {
    margin-left: 16px;
}

.mrg-left-20-px {
    margin-left: 20px;
}

.mrg-left-30-px {
    margin-left: 30px;
}

.mrg-left-60-px {
    margin-left: 60px;
}

.mrg-left-80-px {
    margin-left: 80px;
}

.mrg-left-120-px {
    margin-left: 120px;
}

.mrg-right-20-px {
    margin-right: 20px;
}

.pdg-left-20-prc {
    padding-left: 20%;
}

.pdg-right-50-px {
    padding-right: 50px;
}

.pdg-left-50-px {
    padding-left: 50px;
}

.pdg-top-10-px {
    padding-top: 10px;
}

.pdg-top-40-px {
    padding-top: 40px;
}

.pdg-bottom-10-px {
    padding-bottom: 10px;
}

.pdg-40-px {
    padding: 40px;
}

.pdg-70-px {
    padding: 70px;
}

.pdg-left-125-px {
    padding-left: 125px;
}

.w-auto {
    width: auto;
}

.w-0 {
    width: 0;
}

.w-h-100-vwh {
    width: 100vw;
    height: 100vh;
}

.w-h-100-prc {
    width: 100%;
    height: 100%;
}

.w-100-prc {
    width: 100%;
}

.w-95-prc {
    width: 95%;
}

.w-90-prc {
    width: 90%;
}

.w-80-prc {
    width: 80%;
}

.w-86-prc {
    width: 86%;
}

.w-65-prc {
    width: 65%;
}

.w-75-prc {
    width: 75%;
}

.w-49-prc {
    width: 49%;
}

.w-45-prc {
    width: 45%;
}

.w-35-prc {
    width: 35%;
}

.w-25-prc {
    width: 25%;
}

.w-10-px {
    width: 10px;
}

.w-16-px {
    width: 16px;
}

.w-40-px {
    width: 40px;
}

.w-60-px {
    width: 60px;
}

.w-55-prc {
    width: 55%;
}

.w-50-prc {
    width: 50%;
}

.w-45-prc {
    width: 45%;
}

.w-40-prc {
    width: 40%;
}

.w-25-prc {
    width: 25%;
}

.w-20-prc {
    width: 20%;
}

.mw-100-prc {
    max-width: 100%;
}

.w-100-vw {
    width: 100vw;
}

.h-auto {
    height: auto;
}

.h-100-prc {
    height: 100%;
}

.h-99-vh {
    height: 99vh;
}

.h-90-prc {
    height: 90%;
}

.h-87-prc {
    height: 87%;
}

.h-80-prc {
    height: 80%;
}

.h-75-prc {
    height: 75%;
}

.h-35-prc {
    height: 35%;
}

.h-100-vh {
    height: 100vh;
}

.h-80-vh {
    height: 80vh;
}

.h-80-px {
    height: 80px;
}

.h-100-px {
    height: 100px;
}

.h-140-px {
    height: 140px;
}

.h-50-prc {
    height: 50%;
}

.h-50-px {
    height: 50px;
}

.h-20-px {
    height: 20px;
}

.color-white {
    color: #ffffff;
}

.font-G-B {
    font-family: Gilroy-Bold;
}

.nb {
    font-size: 70px;
    font-weight: 700;
    font-style: normal;
    text-align: left;
    letter-spacing: normal;
    height: 80px;
}

.nb-small {
    font-size: 0.8rem;
    font-weight: 700;
    font-style: normal;
    line-height: 40px;
    text-align: left;
    letter-spacing: normal;
}

.btn {
    color: white;
    text-decoration: none;
    border: solid 0.9px;
    border-radius: 50px;
    padding: 10px 5px 10px 20px;
    font-size: 12px;
    font-family: Gilroy-Bold;
}

.obj-btn {
    width: 12px;
    height: 12px;
    margin: 0px 10px 0 20px;
}

.fd-row-reverse {
    flex-direction: row-reverse;
}

.line-height-50-px {
    line-height: 50px;
}

.ta-center {
    text-align: center;
}

.bg-h {
    background-color: #373b46;
}

.bg-org {
    background-color: $primary;
}

.bg-white {
    background-color: #ffffff;
}

.fts-6-rem {
    font-size: 6.5rem;
}

.ff-Helvetica {
    font-family: Helvetica;
}

.ff-Gilroy_Bold {
    font-family: Gilroy-Bold;
}

.fs-2-rem {
    font-size: 2rem;
}

.fs-3_5-rem {
    font-size: 3.5rem;
}

.fs-4-rem {
    font-size: 4rem;
}

.c-w {
    color: white;
}

.c-gray {
    color: #6d6f77;
}

.c-blue-space {
    color: #294b7e;
}

.bg-26 {
    background-color: $primary;
}

.lh-70-px {
    line-height: 70px;
}

.lh-22-px {
    line-height: 22px;
}

.border {
    border-left: solid 1px;
    height: 15%;
    position: relative;
    top: -20px;
}

.brd-top-solid {
    border-top: solid;
}

.brd-bottom-solid {
    border-bottom: solid;
}

.brd-color-gray {
    border-color: #6d6f77;
}

.video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    -o-object-fit: cover;
}

.ws-pre-line {
    white-space: pre-line;
}

.videoContainer {
    position: absolute;
    // width: 100%;
    z-index: 1;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.wrapper {
    z-index: 2;
    width: 50%;
    position: relative;
    top: 30%;
    right: -30%;
    @include media("<desktop") {}
    @include media("<laptopXL") {}
    @include media("<laptopM") {
        right: 0;
        width: 100%;
    }
}

.text-1 {
    top: 0;
}

.text-2 {
    top: -20%;
    @include media("<desktop") {}
    @include media("<laptopXL") {}
    @include media("<laptopM") {
        top: 0%;
    }
}

.text-3 {
    top: 10%;
}

.left {
    right: 21%;
    @include media("<desktop") {}
    @include media("<laptopXL") {}
    @include media("<laptopM") {
        right: 0;
        width: 100%;
    }
}

.-h2 {
    font-size: 4.5rem;
    line-height: 60px;
    margin-bottom: 50px;
    @include media("<desktop") {}
    @include media("<laptopXL") {}
    @include media("<laptopM") {
        font-size: 2.5rem;
        line-height: 37px;
    }
}

.-p {
    width: 60ch;
}

.home-video-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(29, 26, 23, 0.4);
    z-index: 1;
}

@include media("<=mobileM") {
    .btn-object {
        margin: 0px 10px 0 10px;
    }
    .obj-btn {
        margin: 0px 10px 0 10px;
    }
}