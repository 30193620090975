#main-mentions-legales {
	background-color: #fff;

	#mentionsLegales {
		min-height: 100vh;
		padding: 150px 150px 90px;
		max-width: 1300px;
		margin: auto;

		@include media("<tablet") {
			padding: 130px 20px 50px;
		}

		h1 {
			color: $primary;
			font-family: "Gilroy-Bold";
			font-size: 80px;
			line-height: 85px;

			@include media("<tablet") {
				font-size: 46px;
				line-height: 65px;
			}
		}

		h2 {
			color: $primary;
			font-family: "Gilroy-Bold";
			font-size: 30px;
			margin-top: 40px;
			margin-bottom: 20px;

			@include media("<tablet") {
				font-size: 24px;
			}
		}

		h3 {
			color: $grey;
			font-family: "Gilroy-Bold";
			font-size: 24px;
			margin-top: 40px;
			margin-bottom: 0px;

			@include media("<tablet") {
				font-size: 18px;
			}
		}

		p {
			// color: $primary;
			color: $grey;
			font-family: Helvetica;
			font-size: 15px;
			line-height: 23px;
			margin: 20px 0;

			@include media("<tablet") {
				font-size: 14px;
			}

			a {
				color: $blue2;
				transition: color 0.3s;

				&:hover {
					color: $blue;
				}
			}
		}

		ul {
			color: $grey;
			padding-left: 40px;
			list-style: disc;
		}
	}
}
