#immoRight {
	.figureImmo1 {
		position: relative;
		height: 100%;

		figure {
			position: absolute;

			@include media("<tablet") {
				width: 250px;
			}

			@include media("<mobileS") {
				width: 200px;
			}

			&:nth-child(1) {
				bottom: 0;
				margin: auto;
				transform: rotate(7deg);
				right: 20px;
				left: initial;
			}

			&:nth-child(2) {
				top: 0;
				bottom: 0;
				margin: auto;
				transform: rotate(-8deg);
				left: 25px;
				z-index: 1;
			}

			&:nth-child(3) {
				top: -60px;
				margin: auto;
				transform: rotate(6deg);
				left: 0;
				right: 0;
			}

			height: 340px;
			width: 250px;

			img {
				object-fit: cover;
				height: 100%;
			}
		}
	}
	.figure2 {
		figure {
			height: 100% !important;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 50px 25px 0 !important;
		gap: 25px;

		@include media("<tablet") {
			gap: 15px;
		}

		@include media("<mobileS") {
			padding: 60px 10px 10px 5px !important;
		}

		.card {
			width: 100%;

			img {
				width: 100%;
				height: 260px;
				object-fit: cover;

				@include media("<tablet") {
					height: 150px;
				}

				@include media("<mobileS") {
					height: 100px;
				}
			}

			.cardContent {
				h3 {
					color: $blue;
					font-family: "Gilroy-Bold";
					font-size: 34px;
					padding: 17px 0;
					border-bottom: 1px solid $blue;

					@include media("<tablet") {
						font-size: 20px;
					}

					@include media("<mobileS") {
						font-size: 16px;
					}
				}

				p {
					font-size: 18px !important;
					line-height: 24px !important;
					padding-top: 15px;

					@include media("<tablet") {
						font-size: 12px !important;
						line-height: 14px !important;
					}
				}
			}
		}
	}
}
