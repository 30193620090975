@keyframes fadeFirstVideo {
	0% {
		transform: translateY(-100%);
		filter: grayscale(0);
	}
	40% {
		filter: grayscale(0);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes fadeSecondVideo {
	0% {
		transform: translateY(-100%);
		filter: grayscale(0);
	}
	20% {
		transform: translateY(-100%);
		filter: grayscale(0);
	}
	60% {
		filter: grayscale(0);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes fadeThirdVideo {
	0% {
		transform: translateY(-100%);
		filter: grayscale(0);
	}
	30% {
		transform: translateY(-100%);
		filter: grayscale(0);
	}
	70% {
		filter: grayscale(0);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes fadeFirstVideoX {
	0% {
		transform: translateX(-100%);
		filter: grayscale(0) brightness(1);
	}
	40% {
		filter: grayscale(0) brightness(1);
	}
	100% {
		transform: translateX(0px);
		filter: grayscale(0.4) brightness(0.5);
	}
}

@keyframes fadeSecondVideoX {
	0% {
		transform: translateX(100%);
		filter: grayscale(0) brightness(1);
	}
	40% {
		filter: grayscale(0) brightness(1);
	}
	100% {
		transform: translateX(0px);
		filter: grayscale(0.4) brightness(0.5);
	}
}

@keyframes fadeThirdVideoX {
	0% {
		transform: translateX(-100%);
		filter: grayscale(0) brightness(1);
	}
	40% {
		filter: grayscale(0) brightness(1);
	}
	100% {
		transform: translateX(0px);
		filter: grayscale(0.4) brightness(0.5);
	}
}

@keyframes fadeHomeTitle {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	40% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes fadeHomeSubTitle {
	0% {
		transform: translateY(40px);
		opacity: 0;
	}
	65% {
		transform: translateY(40px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes fadeHomeSubTitleTwo {
	0% {
		transform: translateY(40px);
		opacity: 0;
	}
	65% {
		transform: translateY(40px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes fadeHomeSubTitleThree {
	0% {
		transform: translateY(40px);
		opacity: 0;
	}
	65% {
		transform: translateY(40px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes clignotant {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeHeader {
	0% {
		transform: translateY(-100px);
	}
	85% {
		transform: translateY(-100px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes fadeHeaderContact {
	0% {
		transform: translateX(-100px);
	}
	85% {
		transform: translateX(-100px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes borderLineHead {
	0% {
		width: 0px;
	}
	50% {
		width: 0px;
	}
	100% {
		width: 91.5%;
	}
}

@keyframes borderLineHeadHide {
	0% {
		width: 93%;
	}
	100% {
		width: 0px;
	}
}

@keyframes ctaScrollBounce {
	0% {
		transform: translateY(0px);
	}
	10% {
		transform: translateY(10px);
	}
	20% {
		transform: translateY(0px);
	}
	30% {
		transform: translateY(10px);
	}
	40% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes bounceY {
	0% {
		transform: translateY(0);
	}
	10% {
		transform: translateY(-2px);
	}
	20% {
		transform: translateY(2px);
	}
	30% {
		transform: translateY(-2px);
	}
	40% {
		transform: translateY(2px);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes bounceY2 {
	0% {
		transform: translateY(0);
	}
	10% {
		transform: translateY(2px);
	}
	20% {
		transform: translateY(-2px);
	}
	30% {
		transform: translateY(2px);
	}
	40% {
		transform: translateY(-2px);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes fadeTitleMetier {
	0% {
		transform: translateX(70%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fadeTitleMetier2 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeParaMetier {
	0% {
		height: 100%;
		opacity: 1;
	}
	40% {
		height: 100%;
		opacity: 1;
	}
	100% {
		height: 0;
		opacity: 1;
	}
}

@keyframes fadeParaMetier2 {
	0% {
		height: 100%;
		opacity: 1;
	}
	50% {
		height: 100%;
		opacity: 1;
	}
	100% {
		height: 0;
		opacity: 1;
	}
}

@keyframes fadeUlMetier {
	0% {
		width: 0;
	}
	100% {
		width: 80%;
	}
}

@keyframes fadeUlMetier2 {
	0% {
		width: 0;
	}
	50% {
		width: 0;
	}
	100% {
		width: 80%;
	}
}

@keyframes fadeUlMetier3 {
	0% {
		width: 0;
	}
	50% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes fadeUlLiMetier {
	0% {
		width: 100%;
	}
	30% {
		right: 20px;
	}
	40% {
		width: 100%;
		right: 20px;
	}
	100% {
		width: 0;
		right: 20px;
	}
}

@keyframes fadeUlLiMetier2 {
	0% {
		width: 100%;
	}
	50% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}

@keyframes fadeUlLiCardMetier {
	0% {
		height: 0;
	}
	100% {
		height: 100%;
	}
}

@keyframes fadeFigureMetier {
	0% {
		width: 0;
		transform: translateX(-150px);
	}
	100% {
		width: 48%;
		transform: translateX(0);
	}
}

@keyframes fadeFigureMetier2 {
	0% {
		transform: translateX(30%);
	}
	100% {
		transform: translateX(-30%);
	}
}

@keyframes fadeFigureMetier22 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0%);
	}
}

@keyframes fadeFigureMetier3 {
	0% {
		transform: translateX(30%);
	}
	100% {
		transform: translateX(-9%);
	}
}

@keyframes fadeFigureMetier3one {
	0% {
		width: 0;
		transform: rotate(0) translate(0, 91px) scale(1.05);
		right: -(100% / 3);
	}
	50% {
		transform: rotate(0) translate(0, 91px) scale(1.05);
		right: -(100% / 3);
		width: 30%;
	}
	100% {
		transform: rotate(-5deg) translate(0, 0) scale(1.05);
		right: -80px;
	}
}

@keyframes fadeFigureMetier3two {
	0% {
		width: 0;
	}
	50% {
		width: 30%;
	}
	100% {
		width: 30%;
	}
}

@keyframes fadeFigureMetier3three {
	0% {
		width: 0;
		transform: rotate(0) translate(0, 91px) scale(1.05);
		left: -(100% / 3);
	}
	50% {
		width: 30%;
		transform: rotate(0) translate(0, 91px) scale(1.05);
		left: -(100% / 3);
	}
	100% {
		transform: rotate(5deg) translate(0, 0) scale(1.05);
		left: -80px;
	}
}

@keyframes fadeParentFigureMetier {
	0% {
		transform: translateX(200%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes fadeParentFigureMetier2 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes fadeParentFigureMetier3 {
	0% {
		transform: translateX(50%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

// ---------- Traçage du CTA en savoir plus home section metier ----------

@keyframes height {
	0% {
		transform: translateY(35px);
	}
	80% {
		transform: translateY(35px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes height2 {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

@keyframes width {
	0% {
		transform: scaleX(0);
	}
	35% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

@keyframes width2 {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}
