#content {
	min-height: 100vh;
	padding: 150px 150px 90px;
	max-width: 1300px;
	margin: auto;

	@include media("<tablet") {
		padding: 130px 20px 50px;
	}

	h1 {
		color: $blue2;
		font-family: "Gilroy-Bold";
		font-size: 80px;
		line-height: 85px;

		@include media("<tablet") {
			font-size: 46px;
			line-height: 65px;
		}
	}

	.recrutement {
		border-top: 1px solid $blue2;
		border-bottom: 1px solid $blue2;
		padding: 10px 0;
		font-size: 67px;
		text-align: center;
		margin-bottom: 15px;
	}

	.date {
		color: $blue2;
		font-family: Helvetica;
		font-size: 20px;
		margin-bottom: 50px;
	}

	> div {
		margin: 30px 0;

		h2 {
			color: $blue2;
			font-family: "Gilroy-Bold";
			font-size: 30px;
			margin-top: 40px;
			margin-bottom: 20px;

			@include media("<tablet") {
				font-size: 24px;
			}
		}

		h3 {
			color: $blue2;
			font-size: 22px;

			@include media("<tablet") {
				font-size: 18px;
			}
		}

		p {
			color: $grey;
			font-family: Helvetica;
			font-size: 15px;
			line-height: 23px;
			margin: 20px 0;

			@include media("<tablet") {
				font-size: 14px;
			}
		}

		ul {
			list-style: initial;
			padding-left: 40px;
			color: $grey;
		}

		figure {
			width: 100%;
			height: 400px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		blockquote {
			color: $blue2;
			position: relative;
			padding: 50px 60px 40px;
			max-width: 900px;
			text-align: center;
			margin: auto;

			@include media("<tablet") {
				padding: 20px;
			}

			&::before,
			&::after {
				content: '"';
				position: absolute;
				font-size: 90px;

				@include media("<tablet") {
					font-size: 50px;
				}
			}

			&::before {
				top: 10px;
				left: 20px;

				@include media("<tablet") {
					left: 0px;
				}
			}

			&::after {
				right: 30px;
				bottom: 80px;
				line-height: 0;

				@include media("<tablet") {
					right: 5px;
					bottom: 40px;
				}
			}

			p {
				color: $blue2;
				font-family: "Gilroy-Bold";
				font-size: 42px;
				line-height: 50px;
				margin: 0 0 10px;

				@include media("<tablet") {
					font-size: 24px;
					line-height: 30px;
				}
			}

			cite {
				font-size: 16px;
				text-align: center;
				width: 100%;
			}
		}
	}
}
