.paginateLinks {
	grid-row: 3;
	grid-column: 1 / 23;
	text-align: center;

	ul {
		display: flex;
		justify-content: center;
		align-items: center;
		color: $beige;

		& > li {
			padding: 0 10px;

			a:hover {
				color: $beige;
			}

			.current {
				color: $beige;
				font-weight: 700;
				border-bottom: 1px solid $beige;
			}

			&:first-child,
			&:last-child {
				position: relative;

				a {
					padding: 0;
					display: flex;
					justify-content: center;
					width: 40px;
					height: 40px;
					border: 1px solid $beige;
					border-radius: 50%;
					font-size: 28px;
					color: $beige;
					align-items: center;
					transition: 0.2s box-shadow;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 3px;
						bottom: 0;
						width: 9px;
						height: 9px;
						margin: auto;
						border: 2px solid $beige;
						border-left: none;
						border-bottom: none;
						transform: rotate(45deg);
						transition: 0.2s border-color;
					}

					&:hover {
						box-shadow: inset 0 0 0 35px $beige;

						&::before {
							border-color: $primary;
						}
					}
				}
			}

			&:first-child {
				a {
					transform: rotate(180deg);
				}
			}
		}
	}
}
