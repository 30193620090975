#distribRight,
#immoRight,
#financeRight {
	height: 100vh;
	background-color: $beige;
	display: flex;
	position: fixed;
	z-index: 999;
	width: 100%;
	transform: translateX(100%);
	transition: transform 1s cubic-bezier(0, 0, 0.2, 1);
	top: 0;

	@include media(">laptop") {
		display: none;
	}

	.slider {
		max-width: 80vw;
		position: relative;

		@include media("<laptop") {
			max-width: 100%;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			width: 45px;
			@include scrimGradient($primary, "to right");
			z-index: 1;

			@include media("<laptopL") {
				width: 35px;
			}

			@include media("<laptop") {
				display: none;
			}
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
			transform: rotate(180deg);
		}

		& > a:first-child {
			transform: translateY(-140%);
			width: 30%;
			height: 100%;
			padding: 0;
		}

		.slidePage {
			display: flex;
			padding: 0 0px;
			align-items: center;
			height: 100%;

			.promotion {
				padding-top: 25px;
			}

			> li {
				padding: 0 50px;
				flex: 0 0 100%;

				@include media("<tablet") {
					padding: 0 20px;
				}

				h2 {
					margin-bottom: 30px;
					font-size: 64px;
					line-height: 70px;
					color: $blue;

					@include media("<tablet") {
						font-size: 32px;
						line-height: 40px;
					}

					@include media("<mobileS") {
						font-size: 28px;
						line-height: 34px;
						margin-bottom: 15px;
					}
				}

				p {
					color: $grey;
					font-family: Helvetica;
					font-size: 20px;
					line-height: 30px;
					width: 100%;
					margin: 0;
					text-align: justify;

					@include media("<tablet") {
						font-size: 12px;
						line-height: 20px;
					}
				}

				ul {
					margin: 50px 0 0;

					@include media("<mobileS") {
						margin: 30px 0 0;
					}

					li {
						color: $blue;
						font-family: "Gilroy-Bold";
						font-size: 32px;
						line-height: 45px;
						border-top: 1px solid $blue;
						padding: 15px 0;
						position: relative;

						@include media("<tablet") {
							font-size: 16px;
							line-height: 16px;
						}

						@include media("<mobileS") {
							font-size: 14px;
							line-height: 14px;
						}

						&:last-child {
							border-bottom: 1px solid $blue;
						}

						&:not(.noArrow) {
							&::before {
								content: "";
								position: absolute;
								width: 20px;
								height: 20px;
								border-top: 4px solid $blue;
								border-right: 4px solid $blue;
								margin: auto;
								right: 0;
								top: 0;
								bottom: 0;

								@include media("<tablet") {
									border-top: 2px solid $blue;
									border-right: 2px solid $blue;
									width: 15px;
									height: 15px;
								}
							}

							&::after {
								content: "";
								position: absolute;
								width: 27px;
								height: 4px;
								background-color: $blue;
								border-radius: 1px;
								margin: auto;
								top: -1px;
								bottom: 0;
								right: -5px;
								transform: rotate(-45deg) translateX(-6px);
								transition: all 0.3s ease-in;

								@include media("<tablet") {
									height: 2px;
									width: 20px;
									top: -5px;
								}
							}
						}
					}

					.noArrow {
						display: flex;
						justify-content: space-between;

						a {
							font-size: 22px;

							@include media("<tablet") {
								font-size: 14px;
							}
						}
					}
				}
			}

			.figure {
				&1 {
					position: relative;
					height: 100%;

					figure {
						height: auto;
						width: max-content;
						position: absolute;

						@include media("<tablet") {
							width: 250px;
						}

						@include media("<mobileS") {
							width: 200px;
						}

						&:nth-child(1) {
							top: -80px;
							left: 0;

							@include media("<tablet") {
								top: -30px;
							}
						}
						&:nth-child(2) {
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							margin: auto;
							height: max-content;
						}
						&:nth-child(3) {
							left: initial;
							right: 0;
							bottom: -80px;
							margin: auto;
							height: max-content;

							@include media("<tablet") {
								bottom: -30px;
							}
						}
					}
				}
				&2,
				&3 {
					height: 100%;
					padding: 0;
					position: relative;

					figure {
						position: absolute;
						top: 0;
						width: 100%;
						height: auto;
						left: 0;

						img {
							width: 100%;
						}
					}
				}
				&2 {
					@include media("<tablet") {
						figure {
							height: 100%;
							max-height: 100%;

							img {
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
				&3 {
					figure {
						height: 100%;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
	}

	.nav {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 50px;
		width: max-content;

		display: none;

		img {
			margin: 30px 10px;
			transition: all 0.3s;

			&:hover {
				cursor: pointer;
				transform: scale(1.1);
			}
		}

		.navArrow {
			position: relative;
			width: 40px;
			height: 40px;
			border: 2px solid $blue;
			border-radius: 50%;
			transition: all 0.3s;
			margin-top: 30px;

			&.prev {
				margin-right: 25px;
				transform: rotate(180deg);
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: -6px;
				bottom: 0;
				width: 12px;
				height: 12px;
				margin: auto;
				border: 2px solid $blue;
				border-left: none;
				border-bottom: none;
				transform: rotate(45deg);
				transition: 0.2s border-color;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0px;
				width: 20px;
				height: 2px;
				margin: auto;
				background-color: $blue;
			}

			&:disabled {
				filter: brightness(0.5);
			}

			&:not(:disabled) {
				&:hover {
					&:nth-child(1) {
						transform: rotate(180deg) scale(1.1);
					}

					&:nth-child(2) {
						transform: scale(1.1);
					}
				}
			}
		}
	}

	#grpCard {
		overflow-y: scroll;
		overflow-x: hidden;

		ul {
			align-items: initial;

			padding: 120px 0;

			li {
				p {
					padding-bottom: 30px;
				}
			}
		}
	}
}
