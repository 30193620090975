#projets {
    height: initial;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    #project,
    #projectR {
        height: auto;
        width: 100%;
        overflow: auto;
        display: flex;
        justify-content: center;

        p {
            height: initial;
        }

        .project-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: initial;
            margin-top: 0;
            margin-bottom: 5%;
            width: 400px;

            #wrap-project-head {
                display: flex;
                flex-direction: column;
                width: 90%;

                h2 {
                    height: initial;
                    color: #3f6e88;
                    font-size: 80px;
                    text-align: left;
                    font-family: "Gilroy-Bold";

                    @include media("<laptop") {
                        font-size: 54px;
                        margin-top: 50px;
                    }
                }

                div {
                    margin-top: 30px;
                    width: 100%;
                    height: 55px;
                    border-bottom: solid 1.5px #3f6e88;
                    border-top: solid 1.5px #3f6e88;
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        color: #3f6e88;
                        font-family: "Gilroy-Bold";
                        font-size: 20px;
                    }

                    svg {
                        fill: #3f6e88;
                    }
                }
            }

            .wrp-project-img {
                width: 90%;
                margin-top: 50px;
                position: relative;

                @include media("<tablet") {
                    margin-top: 25px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                .wrp-project-img-infos {
                    position: absolute;
                    transform: translate(50%, 0%);
                    left: -50%;
                    bottom: 0%;
                    background-color: #3f6e88;
                    width: 100%;
                    min-height: 100px;
                    max-height: 180px;
                    padding: 25px;
                    opacity: 0;
                    transition: opacity 0.6s;

                    @include media("<tablet") {
                        padding: 10px;
                    }

                    @include media("<mobileS") {
                        padding: 10px 5px;
                    }

                    h3 {
                        color: $beige;
                        font-family: Gilroy-Bold;
                        font-size: 26px;

                        @include media("<tablet") {
                            font-size: 18px;
                        }

                        @include media("<mobileS") {
                            font-size: 16px;
                        }
                    }

                    p {
                        color: $beige;
                        font-family: Gilroy-Bold;
                        font-size: 12px;
                        letter-spacing: 2px;
                        width: 70%;
                        margin: 15px 0px;

                        @include media("<tablet") {
                            letter-spacing: normal;
                            width: 100%;
                        }

                        @include media("<mobileS") {
                            font-family: Helvetica;
                            margin: 10px 0;
                        }
                    }

                    a {
                        display: flex;
                        justify-content: center;
                        color: $beige;
                        font-family: Gilroy-Bold;
                        font-size: 12px;
                        max-width: 139px;
                        border: 1px solid #fff;
                        border-radius: 20px;
                        padding: 5px;
                        align-items: center;
                        line-height: 0px;

                        svg {
                            fill: white;
                            width: 14px;
                            margin-left: 20px;

                            @include media("<mobileS") {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }

            .wrp-project-img:hover .wrp-project-img-infos {
                opacity: 1;
            }
        }
    }

    #project {
        @include media("<laptop") {
            display: none;
        }
    }

    #projectR {
        display: grid;
        padding: 0 20px;

        @include media(">=laptop") {
            display: none;
        }

        @include media("<mobileS") {
            padding: 0 10px;
        }

        #wrap-project-head {
            display: flex;
            flex-direction: column;
            width: 100%;

            h2 {
                height: initial;
                color: #3f6e88;
                font-size: 80px;
                text-align: left;
                font-family: "Gilroy-Bold";

                @include media("<laptop") {
                    font-size: 54px;
                    margin-top: 50px;
                }

                @include media("<tablet") {
                    margin-top: 15px;
                    font-size: 35px;
                }

                @include media("<mobileS") {
                    font-size: 28px;
                }
            }

            div {
                margin-top: 30px;
                width: 100%;
                height: 55px;
                border-bottom: solid 1.5px #3f6e88;
                border-top: solid 1.5px #3f6e88;
                display: flex;
                align-items: center;

                a {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    color: #3f6e88;
                    font-family: "Gilroy-Bold";
                    font-size: 20px;

                    @include media("<mobileS") {
                        font-size: 16px;
                    }
                }

                svg {
                    fill: #3f6e88;
                }
            }
        }

        .d-flex {
            gap: 0 20px;

            @include media("<mobileS") {
                gap: 0 10px;
            }
        }

        .project-items {
            width: 50%;

            #wrap-project-head {
                width: 100%;
            }
            .wrp-project-img {
                width: 100%;
            }
        }
    }

    .paginateLinks {
        padding-bottom: 50px;

        ul {
            color: #3f6e88;

            & > li {
                padding: 0 10px;

                a:hover {
                    color: $blue;
                }

                .current {
                    color: $blue;
                    border-bottom: 1px solid $blue;
                }

                &:first-child,
                &:last-child {
                    position: relative;

                    a {
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        border: 1px solid #3f6e88;
                        border-radius: 50%;
                        font-size: 28px;
                        color: #3f6e88;
                        align-items: center;
                        transition: 0.2s box-shadow;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 3px;
                            bottom: 0;
                            width: 9px;
                            height: 9px;
                            margin: auto;
                            border: 2px solid #3f6e88;
                            border-left: none;
                            border-bottom: none;
                            transform: rotate(45deg);
                            transition: 0.2s border-color;
                        }

                        &:hover {
                            box-shadow: inset 0 0 0 35px #3f6e88;

                            &::before {
                                border-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
