@import "node_modules/include-media/dist/include-media";
@import "variables";
@import "mixins";
@import "keyframes";
@import "node_modules/destyle.css/destyle";

html {
    scroll-behavior: smooth;
    height: -webkit-fill-available;
    
    // @include media("<=tablet") {
    //     scroll-snap-points-y: repeat(100%);
    //     scroll-snap-type: y mandatory;
    // }
}

body {
    background-color: $primary;
    width: 100vw;
    font-family: Helvetica;
    overflow-x: hidden;
    h1,
    h2 {
        font-family: "Gilroy-Bold";
    }

    .intersection {
        transition: all 0.6s;
    }
}

// Animation Scroll
#home {
    // @include media(">tablet") {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    // }

    > div,
    > section {
        // @include media(">tablet") {
        scroll-snap-align: end;
        scroll-snap-stop: always;
        // }

        // &:not(.sectionRight) {
        //     @include media("<=tablet") {
        //         min-height: -webkit-fill-available;
        //         scroll-snap-align: start;
        //         position: relative;
        //     }
        // }
    }

    // https://www.amitmerchant.com/designing-websites-keeping-floating-address-bar-safari-15-ios/
    padding-bottom: calc(1em + env(safe-area-inset-bottom));
}

.circleArrow {
    position: absolute;
    width: 70px;
    height: 70px;
    border: 2px solid $blue;
    border-radius: 50%;
    left: -100px;
    z-index: 2;
    transition: transform 0.2s;

    &::before {
        content: "";
        position: absolute;
        width: 22px;
        height: 2px;
        left: 22px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: $blue;
    }

    &::after {
        content: "";
        position: absolute;
        width: 13px;
        height: 13px;
        right: 22px;
        transform: rotate(45deg);
        margin: auto;
        top: 0;
        bottom: 0;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
    }
}

::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    background: $primary;
}

::-webkit-scrollbar-thumb {
    background: $beige;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $beige, $alpha: 0.8);
}

@font-face {
    font-family: Gilroy-Bold;
    src: url("../fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: Gilroy-Light;
    src: url("../fonts/Gilroy-Light.otf") format("truetype");
}

@import "partials/header/index.scss";
@import "partials/menu/index";
@import "partials/footer/footer.scss";

@import "partials/home/frmyrk.scss";
@import "partials/home/index";
@import "partials/home/group.scss";
@import "partials/home/organigramme.scss";
@import "partials/home/tolefi-immobiler.scss";
@import "partials/home/finance.scss";
@import "partials/home/actu.scss";

@import "partials/actualites/index";

@import "partials/TolefiImmobilier/index";
@import "partials/TolefiDistribution/index";
@import "partials/TolefiFinance/index";

@import "partials/projects/index";
@import "partials/contact/contact";
@import "partials/article/index";
@import "partials/mentionsLegales/index";

@import "partials/ctaScroll";
@import "partials/paginate";
